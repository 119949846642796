import { RenderCondition } from "@chords/design-system/render";
import {
  PhaseContainer,
  PhaseContainerProps,
} from "../singleSongUpload/PhaseContainer";
import { Button, ButtonProps, Typography } from "@chords/design-system";
import Input from "../../Input";
import { Icon } from "@iconify/react";
import { UploadSongPayload } from "../singleSongUpload/root";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { defaultAlbumSong } from "../../../../../redux/slices/createAlbum";
import { Genre, Themes } from "../../../../../application/song";
import { toast } from "react-toastify";

interface AddAlbumSongProps {
  onCloseModal?: () => void;
  onClickNext?: (payload: any) => void;
  header: string;
  defaultData: Partial<UploadSongPayload>;
  selectAudioButtonProps?: ButtonProps;
  phaseContainerProps?: Partial<PhaseContainerProps>;
}

export const AddAlbumSong = ({
  onCloseModal,
  header,
  onClickNext,
  defaultData,
  selectAudioButtonProps,
  phaseContainerProps,
}: AddAlbumSongProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<any>({});
  const [songPayload, setSongPayload] = useState<Partial<UploadSongPayload>>(
    defaultData ?? defaultAlbumSong
  );

  const isSelectedTheme = React.useCallback(
    (theme: any) => {
      return songPayload.theme?.includes(theme);
    },
    [songPayload.theme]
  );

  const isSelectedGenre = useCallback(
    (genre: any) => {
      return songPayload.genre === genre;
    },
    [songPayload.genre]
  );

  const addTheme = (theme: any) =>
    setSongPayload((p: any) => {
      const local = { ...p, theme: [...p.theme] };
      local.theme.push(theme);
      return local;
    });
  const removeTheme = (theme: any) =>
    setSongPayload({
      ...songPayload,
      theme: songPayload.theme?.filter((thm: any) => thm !== theme),
    });

  const handleOnUpdateGenre = (item: any) => {
    setSongPayload((p) => {
      const local = { ...p };
      local.genre = item;
      return local;
    });
  };

  const handleOnSelectFile = () => {
    const file = inputRef.current?.files?.[0];
    if (file) {
      const isAudio = file.type.startsWith("audio");
      if (!isAudio) {
        return toast("File must be an audio file.", {
          type: "info",
          hideProgressBar: true,
        });
      }
      setSongPayload((p) => ({
        ...p,
        audioSrc: {
          file,
          name: file.name,
        },
      }));
    }
  };

  const handleSelectAudio = () => {
    inputRef.current?.click();
  };

  const handleUploadSong = async () => {
    const errs = createErrors(songPayload);
    const hasErrs = Object.keys(errs).length > 0;
    if (hasErrs) {
      return setErrors(errs);
    }
    await onClickNext?.(songPayload);
  };

  // useEffect(() => {
  //   if (defaultData) {
  //     setSongPayload(defaultData);
  //   }
  // }, [defaultData]);

  return (
    <PhaseContainer
      header={header}
      style={{ height: "700px" }}
      onClickNext={handleUploadSong}
      onClickCancel={onCloseModal}
      {...phaseContainerProps}
    >
      <div className="w-full h-full overflow-auto grid grid-cols-3">
        <div className="col-span-2">
          <Typography variant="h6" className="mb-2">
            Metadata
          </Typography>
          <div className="grid grid-cols-2">
            <div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Title (required)"
                  name={"title"}
                  type={"text"}
                  error={errors.title}
                  value={songPayload?.title}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({ ...songPayload, title: e.target.value });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Composer (required)"
                  name={"composer"}
                  error={errors.composer}
                  type={"text"}
                  value={songPayload.composer!}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      composer: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Conductor (optional)"
                  name={"conductor"}
                  type={"text"}
                  value={songPayload.conductor!}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      conductor: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Composed Date(optional)"
                  name={"composedDate"}
                  type={"date"}
                  value={songPayload?.composedDate}
                  placeholder={"Composed date"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      composedDate: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Soloists (optional)"
                  name={"soloists"}
                  type={"text"}
                  value={songPayload?.soloists}
                  placeholder={
                    "comma-separated list of featured soloists. Eg: Soloist 1, Soloist 2"
                  }
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      soloists: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <div className="w-fit flex flex-col gap-4">
                <Typography
                  isLabel={!errors.genre}
                  isError={errors.genre}
                  variant="pMid"
                >
                  Genre (required - select one)
                </Typography>
                <div className="flex gap-3 flex-wrap">
                  {Genre.map((item) => {
                    const selected = isSelectedGenre(item);
                    return (
                      <Typography
                        key={item}
                        variant="small"
                        className={`p-1 border-[1px] hover:cursor-pointer ${
                          selected
                            ? "border-1 border-chordsDark border-solid"
                            : ""
                        }`}
                        onClick={() => handleOnUpdateGenre(item)}
                      >
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              </div>
              <div className="w-fit flex flex-col gap-4">
                <Typography isLabel variant="pMid">
                  Theme (recommended: at least 1)
                </Typography>
                <div className="flex gap-3 flex-wrap">
                  {Themes.map((item) => {
                    const selected = isSelectedTheme(item);
                    return (
                      <Typography
                        key={item}
                        variant="small"
                        className={`p-1 border-[1px] hover:cursor-pointer ${
                          selected
                            ? "border-1 border-chordsDark border-solid"
                            : ""
                        }`}
                        onClick={() => {
                          if (selected) removeTheme(item);
                          else addTheme(item);
                        }}
                      >
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              </div>
              <div className="max-w-[24rem] mt-5 w-full">
                <Input
                  name={"hymnRefs"}
                  type={"text"}
                  value={songPayload?.hymnRefs}
                  label="References to Hymns (optional) "
                  placeholder={
                    "Comma separated Hymns that this song references"
                  }
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      hymnRefs: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Keywords (optional)"
                  name={"relatedKeywords"}
                  type={"text"}
                  value={songPayload?.relatedKeywords}
                  placeholder={
                    "comma-separated keywords to reference this song"
                  }
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      relatedKeywords: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Description (optional)"
                  name={"description"}
                  type={"text"}
                  value={songPayload.description!}
                  placeholder={
                    "An inspiration behind the song or any relevant info"
                  }
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-full">
          <div className="grid grid-rows-2 h-full">
            <div className="row-span-1">
              <Typography
                variant="h6"
                isError={errors.coverImage}
                className="pb-2"
              >
                Audio File (required)
              </Typography>
              <div className="w-full mx-auto">
                {RenderCondition(
                  songPayload.audioSrc,
                  <div className="flex items-center gap-x-4 mt-2">
                    <div className="flex w-10 h-10 flex-shrink-0 rounded-full justify-center items-center bg-chordsLight">
                      <Icon
                        icon="material-symbols:play-arrow"
                        className=" text-[32px]"
                      />
                    </div>
                    <Typography variant="pMid" className="">
                      {songPayload?.audioSrc?.name}
                    </Typography>
                  </div>,
                  <Typography variant="pMid">None selected</Typography>
                )}
              </div>
              <div className="mt-auto mx-auto flex flex-col items-center">
                <Icon
                  icon="material-symbols:cloud-upload"
                  className="text-[2rem] text-dark-grey"
                />
                <Typography variant="pMid" isLabel className="!my-4">
                  Click to select file from local drive
                </Typography>
                <input
                  onChange={handleOnSelectFile}
                  type="file"
                  ref={inputRef}
                  className="hidden"
                  name="music"
                  id="create-album-song-music"
                  accept="audio/*"
                />
                <Button
                  onClick={handleSelectAudio}
                  variant="nav"
                  className=""
                  {...selectAudioButtonProps}
                >
                  Select File
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PhaseContainer>
  );
};

function createErrors(payload: any) {
  const errors: any = {};
  if (!payload.title) errors.title = "Required";
  if (!payload.composer) errors.composer = "Required";
  if (!payload.genre) errors.genre = "Required";
  if (!payload.audioSrc) errors.coverImage = "Required";
  return errors;
}
