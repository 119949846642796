import styled from "styled-components";

export * from "./Typography";
export * from "./status";
export * from "./modal";
export * from "./loader";
export * from "./button";
export * from "./adminTag";

export const HeaderImageContainer = styled.img`
  height: 50px;
  width: 50px;
  object-fit: cover;
  border-radius: 30px;
`;
