import React from "react";
import { Button, ButtonProps, Time, Typography } from "@chords/design-system";
import {
  PhaseContainer,
  PhaseContainerProps,
} from "../_shared/components/modal/singleSongUpload/PhaseContainer";
import Input from "../_shared/components/Input";
import {
  convertImageFileToBase64Str,
  dateService,
} from "@chords/web-infrastructure";
import { RenderCondition } from "@chords/design-system/render";
import { Icon } from "@iconify/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import {
  SupportedImageFormatMap,
  SupportedImageFormatType,
} from "../../infra/file/supportTypes";

interface ConcertMeta {
  title: string;
  description: string;
  liveStreamLink: string;
  dueDate?: string;
  date: string;
  time: string;
  location: string;
  coverImage: string | null;
}

const initMeta = {
  title: "",
  description: "",
  date: "",
  time: {},
  liveStreamLink: "",
  location: "",
  coverImage: null,
};

interface RootConcertModal {
  onClose?: () => void;
  onSave?: (data?: any) => void;
  onCreateConcert?: () => void;
  containerProps?: Omit<PhaseContainerProps, "header">;
  selectButtonProps?: ButtonProps;
  header: string;
  meta?: ConcertMeta;
}
export const ConcertModal = ({
  containerProps,
  header,
  meta: defaultMeta,
  onSave,
  selectButtonProps,
}: RootConcertModal) => {
  const [selectedImg, setSelectedImg] = useState({ src: "", title: "" });
  const inputRef = useRef<HTMLInputElement>(null);
  const { footerComponent: Footer = "", ...otherContainerProps } = {
    ...containerProps,
  };

  const [meta, setMeta] = useState(initMeta);

  const errors = useMemo(() => {
    return createErrors({ ...meta, coverImage: selectedImg.src });
  }, [meta, selectedImg]);

  const hasErrors = Object.keys(errors).length > 0;

  const min = useMemo(() => {
    return getTodayAsMinDate();
  }, []);

  const parsedMeta = useMemo(() => {
    const time = transformTimeValue(meta.time);
    return { ...meta, time };
  }, [meta]);

  const handleOnChangeTitle = (e: any) => {
    setMeta((p) => ({ ...p, title: e.target.value }));
  };

  const handleOnChangeDesc = (e: any) => {
    setMeta((p) => ({ ...p, description: e.target.value }));
  };

  const handleOnChangeLivestream = (e: any) => {
    setMeta((p) => ({ ...p, liveStreamLink: e.target.value }));
  };

  const handleOnChangeLocation = (e: any) => {
    setMeta((p) => ({
      ...p,
      location: e.target.value,
    }));
  };

  const handleOnChangeDate = (e: any) => {
    setMeta((p) => ({ ...p, date: e.target.value }));
  };

  const handleOnChangeTime = (newValue: any) => {
    setMeta((p) => ({ ...p, time: newValue }));
  };

  const handleSelectCoverimage = () => {
    inputRef.current?.click();
  };

  const handleOnSelectFile = async () => {
    const file = inputRef.current?.files?.[0];
    if (file) {
      const isSupported =
        SupportedImageFormatMap[file.type as SupportedImageFormatType];
      if (!isSupported) {
        alert(
          "This image type is not supported. Reach out to our support for assistance or convert to any of these types: png, jpg, jped, webp"
        );
        return;
      }
      setMeta((p) => ({ ...p, coverImage: file } as any));
      const src = await convertImageFileToBase64Str(file);

      setSelectedImg({ src, title: file?.name ?? "" });
    }
  };

  const handleOnSave = async () => {
    if (hasErrors) {
      toast("Form is incomplete", { hideProgressBar: true, type: "info" });
    } else {
      await onSave?.(parsedMeta);
      setMeta(initMeta);
    }
  };

  useEffect(() => {
    if (defaultMeta) {
      const time = transformTimeToDatePickerFormat(defaultMeta.dueDate);
      setMeta({
        ...defaultMeta,
        time,
      } as any);
      setSelectedImg({
        src: defaultMeta?.coverImage ?? "",
        title: defaultMeta?.title,
      });
    }
  }, [defaultMeta]);

  return (
    <PhaseContainer
      header={header}
      onClickNext={handleOnSave}
      footerComponent={
        Footer
          ? () => {
              return <Footer meta={parsedMeta} />;
            }
          : ""
      }
      {...otherContainerProps}
    >
      <div className="w-full grid grid-cols-3 gap-x-5">
        <div className="col-span-2 mt-5">
          <Typography variant="h6" className="pb-2">
            Metadata
          </Typography>
          <div className="grid grid-cols-5">
            <div className="col-span-3 ">
              <div className=" flex flex-col w-full">
                <Input
                  label="Title (required)"
                  name={"title"}
                  type={"text"}
                  value={meta.title}
                  placeholder={""}
                  onChange={handleOnChangeTitle}
                  error={errors.title}
                />
              </div>
              <div className="w-full">
                <Input
                  label="Description (optional)"
                  name={"description"}
                  type={"text"}
                  value={meta.description}
                  placeholder={""}
                  onChange={handleOnChangeDesc}
                  error={errors.description}
                />
              </div>
              <div className="w-full">
                <Input
                  label="Livestream link (Optional but we recommend you include it later.)"
                  name={"title"}
                  type={"text"}
                  value={meta.liveStreamLink}
                  placeholder={""}
                  onChange={handleOnChangeLivestream}
                />
              </div>
            </div>
            <div className="col-span-2">
              <div className="w-full">
                <Input
                  label="Due date (required)"
                  name={"title"}
                  type={"date"}
                  value={meta.date}
                  min={min}
                  placeholder={""}
                  onChange={handleOnChangeDate}
                  error={errors.date}
                />
              </div>

              <div className="w-full">
                <Typography variant="pMid" isError={errors.time}>
                  Time
                </Typography>
                <Time value={meta.time} onChange={handleOnChangeTime} />
              </div>
              <div className="w-full">
                <Input
                  label="Location (required)"
                  name={"location"}
                  type={"text"}
                  value={meta.location}
                  placeholder={"National Theatre, Ghana"}
                  onChange={handleOnChangeLocation}
                  error={errors.location}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 flex flex-col h-full">
          <Typography variant="h6" className="pb-2" isError={errors.coverImage}>
            Cover Image
          </Typography>
          {RenderCondition(
            !!selectedImg?.src,
            <div className="flex gap-x-4 items-center">
              <img
                src={selectedImg?.src}
                className="w-[100px] h-[95px] rounded-[3px]"
              />
              <Typography variant="pMid" className="">
                {selectedImg.title}
              </Typography>
            </div>,
            <Typography variant="h5" isLabel>
              No Cover Image
            </Typography>
          )}

          <div className="mt-auto flex flex-col items-center">
            <Icon
              icon="material-symbols:cloud-upload"
              className="text-[2rem] text-dark-grey"
            />
            <Typography variant="pMid" isLabel className="!my-4">
              Click to select file from local drive
            </Typography>
            <input
              onChange={handleOnSelectFile}
              type="file"
              ref={inputRef}
              className="hidden"
              name="music"
              id="music"
              accept="image/*"
            />
            <Button
              {...selectButtonProps}
              onClick={handleSelectCoverimage}
              variant="nav"
              className=""
            >
              Select File
            </Button>
          </div>
        </div>
      </div>
    </PhaseContainer>
  );
};

function getTodayAsMinDate() {
  const today = new Date();
  return today.toISOString().split("T")[0];
}

function transformTimeValue(value: any) {
  const mer = value.$H > 12 ? "pm" : "am";
  return `${value?.$H}:${value?.$m}${mer}`;
}

function transformTimeToDatePickerFormat(time: any) {
  return dateService(time);
}

const createErrors = (payload: any) => {
  const errors: any = {};
  if (!payload.title) errors.title = "Required";
  if (!payload.location) errors.location = "Required";
  if (!payload.date) errors.date = "Required";
  if (!Object.keys(payload.time).length) errors.time = "Required";
  if (!payload.coverImage) errors.coverImage = "Reqiured";
  return errors;
};
