import { Icon } from "@iconify/react";
import * as React from "react";
import Phase1 from "./Phase1";
import Phase2 from "./Phase2";
import Phase3 from "./Phase3";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IAlbumUpload {}

const AlbumUploadModal: React.FunctionComponent<IAlbumUpload> = (props) => {
  const [step, setStep] = React.useState(1);

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  switch (step) {
    case 1:
      return <Phase1 nextStep={nextStep} step={step} />;
    case 2:
      return (
        <Phase2
          AddedSongsNode={null}
          prevStep={prevStep}
          nextStep={nextStep}
          step={step}
        />
      );
    case 3:
      return <Phase3 prevStep={prevStep} step={step} />;
    default:
      return null;
  }
};

export default AlbumUploadModal;
