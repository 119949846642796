import * as React from "react";
import { Icon } from "@iconify/react";
import { routes } from "../../../routes";
import { NavLink } from "react-router-dom";
import { Button, Typography } from "@chords/design-system";
import { useAppDispatch } from "../hooks/useRedux";
import { authActions } from "../../../redux/slices/auth.slice";
import { LocalStorage } from "../../../infra/localStorage";
import { useNavigationWithReload } from "../hooks/useAppNavigation";

const Sidebar: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const nav = useNavigationWithReload();
  const logout = () => {
    dispatch(authActions.reseet());
    LocalStorage.clear();
    nav("/signin");
  };
  return (
    <div className="w-[13rem] h-full fixed left-0 top-[70px]  pt-5 flex-shrink-0 flex-grow-0 shadow-[0_4px_10px__2px_rgba(0,0,0,0.25)]">
      <ul className="flex capitalize flex-col h-full gap-y-8">
        {routes.map((item) => {
          return (
            <li key={item.routeName} className="">
              <NavLink
                to={item.link}
                className={({ isActive }) =>
                  `flex font-bold gap-2 cursor-pointer items-center transition-all ml-2 duration-300 ${
                    isActive
                      ? "bg-chordsLightBg text-[#00004D] before:h-8 before:w-[6px] before:bg-chordsDark before:rounded-l-[4px] "
                      : "text-[#a9a9a9] ml-2"
                  }`
                }
              >
                <Icon icon={item.icon} className="text-[18px] " />
                <Typography variant="pMid">{item.routeName}</Typography>
              </NavLink>
            </li>
          );
        })}
        <Button
          onClick={logout}
          className="mt-auto mb-[100px] ml-5"
          variant="nav"
        >
          Signout
        </Button>
      </ul>
    </div>
  );
};

export default Sidebar;
