import { Button, ButtonProps, Typography } from "@chords/design-system";
import { RenderCondition } from "@chords/design-system/render";
import React, { ReactNode } from "react";

export type PhaseContainerProps = React.HTMLAttributes<HTMLDivElement> & {
  header: string;
  footer?: ReactNode;
  footerComponent?: any;
  onClickNext?: (e?: any) => void;
  onClickCancel?: () => void;
  cancelButtonLabel?: string;
  nextButtonLabel?: string;
  cancelbuttonProps?: Omit<ButtonProps, "onClick">;
  nextbuttonProps?: Omit<ButtonProps, "onClick">;
};

export const PhaseContainer = ({
  children,
  header,
  footer,
  onClickNext,
  cancelButtonLabel,
  nextButtonLabel,
  onClickCancel,
  cancelbuttonProps,
  nextbuttonProps,
  footerComponent: Footer = "",
  ...containerProps
}: PhaseContainerProps) => {
  return (
    <div className="overflow-y-auto  flex items-center justify-center h-full bg-black/25  overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full">
      <div
        {...containerProps}
        className="relative bg-white flex flex-col h-[500px] overflow-hidden box-border px-8 py-6 my-4 mx-4 md:mx-8 w-full max-w-7xl"
      >
        <Typography variant="h4">{header}</Typography>
        {children}
        <div className="mt-auto">
          {RenderCondition(
            Boolean(Footer),
            <Footer />,
            <div className="flex flex-shrink-0 gap-x-5 w-fit mt-auto ml-auto">
              <React.Fragment>
                <Button
                  {...cancelbuttonProps}
                  onClick={onClickCancel}
                  variant="cancel"
                >
                  {cancelButtonLabel ?? "Cancel"}
                </Button>
                <Button
                  {...nextbuttonProps}
                  onClick={onClickNext}
                  variant="auth"
                >
                  {nextButtonLabel ?? "Next"}
                </Button>
              </React.Fragment>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
