import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { Loader } from "../..";
import { RenderCondition } from "../../render";

type ButtonVariant = "auth" | "link" | "nav" | "cancel" | "outlined" | "error";

export type ButtonProps = React.HTMLAttributes<HTMLButtonElement> & {
  variant?: ButtonVariant;
  isLoading?: boolean;
  disabled?: boolean;
  isDanger?: boolean;
  loaderProps?: { color: string };
};

const base = styled.button`
  border: none;
  height: 45px;
  justify-content: center;
  cursor: pointer;
  width: 150px;
  background-color: ${({ isDanger }: any) => (isDanger ? "#ed2b2a" : "#000")};
  color: #fff;
  font-size: 12px;
  box-sizing: border-box;

  :disabled {
    cursor: not-allowed;
    border: 1px solid #a9a9a9;
    background-color: #d9d9d9;
    color: #00000090;
  }
`;

const nav = styled(base)`
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
  font-variation-settings: "wght" 600;
  height: fit-content;
  width: fit-content;
  border-radius: 3px;
  padding: 6px;

  :hover {
    background-color: #000;
    color: #fff;
    transition: background-color 0.2s ease-in-out;
  }
`;

const error = styled(base)`
  background-color: #ed2b2a;
`;

const outlined = styled(base)`
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
`;

const auth = base;
const link = styled(base)`
  height: fit-content;
  background-color: transparent;
  color: #000;

  :hover {
    color: #525fe1;
    border-bottom: 1px solid #525fe1;
  }
`;

const cancel = styled(base)`
  background-color: #f1f1f1;
  color: #a9a9a9;
`;

const store: any = {
  nav,
  cancel,
  auth,
  error,
  link,
  outlined,
};

export const Button = ({
  variant,
  children,
  isLoading,
  loaderProps,
  onClick,
  ...props
}: ButtonProps) => {
  const Element = useMemo(() => {
    if (variant) return store[variant];
    return base;
  }, [variant]);

  return (
    <Element onClick={!isLoading ? onClick : () => null} {...props}>
      {RenderCondition(!!isLoading, <Loader {...loaderProps} />, children)}
    </Element>
  );
};
