import React from "react";
import { Typography } from "@chords/design-system";
import { PhaseContainer } from "../singleSongUpload/PhaseContainer";
import { Icon } from "@iconify/react";
import { UploadSongPayload } from "../singleSongUpload/root";

interface AddAlbumSongPreviewProps {
  data: Partial<UploadSongPayload>;
  onClickUpload: () => void;
  onClickPrev: () => void;
}

export const AddAlbumSongPreview = ({
  data,
  onClickPrev,
  onClickUpload,
}: AddAlbumSongPreviewProps) => {
  return (
    <PhaseContainer
      header="Review and Submit"
      nextButtonLabel="Upload Song"
      cancelButtonLabel="Previous"
      cancelbuttonProps={{ className: "!text-[#00000090]" }}
      nextbuttonProps={{
        className: "!w-[200px]",
      }}
      onClickNext={onClickUpload}
      onClickCancel={onClickPrev}
    >
      <div className="grid grid-cols-3 h-full py-5 ">
        <div className="col-span-1">
          <Typography variant="h5">{data?.title}</Typography>

          <div className="mb-5 mt-3">
            <Typography variant="pMid">Audio Source</Typography>
            <div className="flex items-center gap-x-4 mt-2">
              <div className="flex w-10 h-10 flex-shrink-0 rounded-full justify-center items-center bg-chordsLight">
                <Icon
                  icon="material-symbols:play-arrow"
                  className=" text-[32px]"
                />
              </div>
              <Typography variant="pMid" className="">
                {data?.audioSrc?.name}
              </Typography>
            </div>
          </div>
        </div>
        <div className="col-span-2 pl-2">
          <Typography variant="h6" className="mb-2">
            Metadata
          </Typography>
          <div className="grid grid-cols-2">
            <div className="flex flex-col gap-y-4">
              <div>
                <Typography variant="pMid" isLabel>
                  Composer
                </Typography>
                <Typography variant="pMid" isLabel>
                  {data?.composer || "Not Provided"}
                </Typography>
              </div>
              <div>
                <Typography variant="pMid" isLabel>
                  Conductor
                </Typography>
                <Typography variant="pMid" isLabel>
                  {data.conductor || "Not provided"}
                </Typography>
              </div>
              <div>
                <Typography variant="pMid" isLabel>
                  Soloists
                </Typography>
                <Typography variant="p" isLabel>
                  {data?.soloists || "Not Provided"}
                </Typography>
              </div>
              <div>
                <Typography variant="pMid" isLabel>
                  Producer
                </Typography>
                <Typography variant="p" isLabel>
                  {data?.producedBy || "Not Provided"}
                </Typography>
              </div>
              <div>
                <Typography variant="pMid" isLabel>
                  Composed Date
                </Typography>
                <Typography variant="p" isLabel>
                  {data?.composedDate || "Not Provided"}
                </Typography>
              </div>
            </div>
            <div className="gap-y-3 flex flex-col">
              <div>
                <Typography variant="pMid" isLabel>
                  Description
                </Typography>
                <Typography variant="p" isLabel>
                  {data?.description || "Not Provided"}
                </Typography>
              </div>
              <div>
                <Typography variant="pMid" isLabel>
                  Related Keywords
                </Typography>
                <Typography variant="p" isLabel>
                  {data?.relatedKeywords || "Not Provided"}
                </Typography>
              </div>
              <div>
                <Typography variant="pMid" isLabel>
                  Genre
                </Typography>
                <Typography variant="small" className="p-1 w-fit border-[1px]">
                  {data?.genre}
                </Typography>
              </div>
              <div>
                <Typography variant="pMid" isLabel>
                  Themes
                </Typography>
                <div className="flex gap-3">
                  {data?.theme?.map((item) => {
                    return (
                      <Typography
                        key={item}
                        variant="small"
                        className="p-1 border-[1px]"
                      >
                        Genre {item}
                      </Typography>
                    );
                  })}
                </div>
              </div>
              <div>
                <Typography variant="pMid" isLabel>
                  References to hymns
                </Typography>
                <Typography variant="p" isLabel>
                  {data?.hymnRefs || "Not Provided"}
                </Typography>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </PhaseContainer>
  );
};
