export * from "./uploadUtils";
export const Themes = Object.freeze([
  "Contemporary",
  "Psalms",
  "Benedictions",
  "Hopeful",
  "Prayerful",
  "Hallelujahs",
  "Healing and Comfort",
  "Christmas",
  "Easter",
  "Passion and Crucifixion",
  "Praise and Worship",
  "Classics",
  "Soft and Gentle",
  "Piano",
  "Advent",
  "Baroque",
  "Danceables",
]);

export const Genre = Object.freeze([
  "Cantata",
  "Chamber",
  "Opera",
  "Hymn",
  "Mass",
  "Requiem",
  "Motet",
  "Anthem",
  "Highlife (Kelencha)",
  "Agbadza",
  "Folk Choral Music",
  "Negro Spiritual",
  "Accapella",
  "Sacred Music",
  "Contemporary Choral",
  "Classical",
]);
