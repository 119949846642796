import { AssetStatus, Button, Typography } from "@chords/design-system";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { UploadSongPayload } from "./modal/singleSongUpload/root";
import { songUploadUtilService } from "../../../application/song";
import { useToastManager } from "../hooks/useToastManager";
import { RenderIf } from "@chords/design-system/render";
import { useAuth } from "../hooks/useAuth";
import { ICreatorSummaryForResource } from "@chords/core";
import { LocalStorage, StorageKeys } from "../../../infra/localStorage";

interface Props {
  data: Partial<Omit<UploadSongPayload, "type">> & {
    type: "single" | "album" | "album-song";
  };
}

export const UploadItem = ({ data }: Props) => {
  const user = useAuth().user!;
  const id = user.id;
  const [status, setStatus] = useState("in-progress");
  const workerRef = useRef<any>(null);

  const creatorSummary: ICreatorSummaryForResource = {
    coverImage: user.coverImage,
    id,
    name: user.name,
    type: user.type,
  };

  const { show } = useToastManager();

  const uploadMeta = useMemo(() => {
    if (data.type === "album") return { title: (data as any).meta.title };
    return { title: data.title };
  }, [data]);

  const typographyStatus = useMemo(() => {
    return createTypographyStatus(status);
  }, [status]);

  const sanitizedPayload = useMemo(() => {
    let payload;
    if (data.type === "single") {
      payload = songUploadUtilService.createSongPayload(
        data as any,
        creatorSummary
      );
    } else if (data.type === "album") {
      payload = songUploadUtilService.createAlbumPayload(data, creatorSummary);
    } else if (data.type === "album-song") {
      payload = songUploadUtilService.createAlbumSongPayload(
        data,
        creatorSummary
      );
    }
    return payload;
  }, [data]);

  const execWorker = (worker: Worker, payload: typeof sanitizedPayload) => {
    const token = LocalStorage.get(StorageKeys.CLOUD_TOKEN);
    worker.postMessage({ payload, token });
  };

  const handleRetryUpload = (e?: any) => {
    e?.stopPropagation();
    const worker = workerRef.current;
    if (worker) {
      execWorker(worker, sanitizedPayload);
    }
  };

  useEffect(() => {
    const worker = new Worker(
      new URL("../../../workers/worker.js", import.meta.url)
    );
    workerRef.current = worker;
    worker.onmessage = ({ data }) => {
      const { status } = data;
      setStatus(data.status);

      if (status === "error") {
        return show("Upload failed.", { type: "error" });
      } else if (status === "complete")
        return show("Upload complete", { type: "success" });
    };

    execWorker(worker, sanitizedPayload);

    return () => {
      worker.terminate();
    };
  }, []);

  return (
    <div className="p-2 hover:bg-chordsLightBg hover:cursor-pointer">
      <Typography variant="small" className="!inline mr-2">
        {data.type}
      </Typography>
      <Typography variant="pMid" weight="600" className="inline line-clamp-1">
        {uploadMeta.title}
      </Typography>
      <div className="flex w-full items-center justify-between mt-2">
        <Typography variant="lStatus" status={typographyStatus!}>
          {status}
        </Typography>
        {RenderIf(
          status === "error",
          <Button
            onClick={handleRetryUpload}
            className="!bg-[#ed2b2a20] !text-[#ed2b2a] !border-[#ed2b2a20] w-[fit-content] !p-[2px] !px-4"
            variant="nav"
          >
            retry
          </Button>
        )}
      </div>
    </div>
  );
};

function createTypographyStatus(status: string): AssetStatus {
  if (status === "complete") return "approved" as const;
  if (status === "error") return "rejected" as const;
  return "pending";
}
