import * as React from "react";
import { PhaseContainer } from "../singleSongUpload/PhaseContainer";
import { Typography } from "@chords/design-system";
import { UploadEventManager } from "../../../../../lib/events/uploadEventManager";
import { useAlbumModalState } from "../../../hooks/useAlbumModalState";
import { useToastManager } from "../../../hooks/useToastManager";

interface IPhase3Props {
  prevStep: () => void;
  step: number;
}

const Phase3: React.FunctionComponent<IPhase3Props> = ({ prevStep }) => {
  const { show } = useToastManager();
  const {
    toggleOpen,
    data: { meta, songs },
    resetAlbum,
  } = useAlbumModalState();
  const submittedSongs = React.useMemo(() => {
    return Object.values(songs);
  }, [songs]);

  const handleUploadAlbum = () => {
    show(
      "Album is uploading. Do not close or refresh the browser. Keep an eye on the snackbar to see progress",
      { type: "info", autoClose: 2500, hideProgressBar: true }
    );
    UploadEventManager.emitAlbumUpload({ meta, songs: submittedSongs });
    resetAlbum();
    toggleOpen();
  };

  return (
    <PhaseContainer
      cancelButtonLabel="Previous"
      cancelbuttonProps={{ className: "!text-[#00000090]" }}
      nextButtonLabel="Upload"
      onClickCancel={prevStep}
      header="Review and Submit"
      onClickNext={handleUploadAlbum}
    >
      <div className="grid grid-cols-3 mt-4 h-full">
        <div className="col-span-2">
          <div className="grid grid-cols-2">
            <div>
              <Typography variant="h5">{meta?.title}</Typography>
              <div className="mt-3">
                <Typography variant="pMid">Cover image</Typography>
                <div className="flex gap-x-4 items-center">
                  <img
                    src={meta?.coverImage?.src}
                    className="w-[100px] h-[95px] rounded-[3px]"
                  />
                  <Typography variant="pMid" className="">
                    {meta?.coverImage?.name}
                  </Typography>
                </div>
              </div>
            </div>
            <div>
              <Typography variant="h6" className="mb-4">
                Metadata
              </Typography>
              <div className="flex flex-col gap-y-5">
                <div>
                  <Typography variant="pMid" isLabel>
                    Composed Date
                  </Typography>
                  <Typography variant="pMid" isLabel>
                    {meta?.composedDate ?? "Not provided"}
                  </Typography>
                </div>
                <div>
                  <Typography variant="pMid" isLabel>
                    Related Keywords
                  </Typography>
                  <Typography variant="pMid" isLabel>
                    {meta?.relatedKeywords ?? "Not provided"}
                  </Typography>
                </div>
                <div>
                  <Typography variant="pMid" isLabel>
                    Themes
                  </Typography>
                  <div className="flex gap-3 flex-wrap">
                    {meta?.theme?.map((item) => {
                      return (
                        <Typography
                          key={item}
                          variant="small"
                          className="p-1 border-[1px]"
                        >
                          Genre {item}
                        </Typography>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 h-inherit flex flex-col">
          <Typography variant="h6" className="mb-2">
            Songs
          </Typography>
          <div className="h-[300px] overflow-y-auto">
            {submittedSongs.map((song, key) => {
              return (
                <div
                  key={key}
                  className="flex flex-col px-2 py-1 hover:bg-chordsLight w-full"
                >
                  <Typography variant="pMid">{song?.title}</Typography>
                  <Typography variant="small" className="mt-[2px]">
                    {song?.composer}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </PhaseContainer>
  );
};

export default Phase3;
