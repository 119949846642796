import React from "react";
import { TimePicker, TimePickerProps } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

export const Time = (props: TimePickerProps<any>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        sx={{
          fontSize: "10px",
          padding: 0,
          margin: 0,
          borderRadius: 0,
          zIndex: 0,
          ".MuiTimeField-root": {
            height: "40px",
            backgroundColor: "blue",
            zIndex: 999999999999,
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
};
