import styled from "styled-components";
import { Typography } from "..";

export const Container = styled.div`
  width: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`;

export const Cover = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 5px;
  object-fit: cover;
`;

export const Text = styled(Typography)`
  font-size: 14px;
  margin: 0;
  padding: 0;
  padding-top: 8px;
`;
