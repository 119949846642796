import React, { useRef, useState } from "react";
import { Button, Typography } from "@chords/design-system";
import Input from "../../_shared/components/Input";
import { convertImageFileToBase64Str } from "@chords/web-infrastructure";
import { RenderCondition } from "@chords/design-system/render";
import { useMutation } from "react-query";
import { choirService } from "../../../application/choir/choir.service";
import { useToastManager } from "../../_shared/hooks/useToastManager";
import { toast } from "react-toastify";
import { isValidEmail } from "../../../lib/helper/validators";
import {
  SupportedImageFormatMap,
  SupportedImageFormatType,
} from "../../../infra/file/supportTypes";
import { ILoginPayload } from "../../../application/auth/types";

interface SignupFormPayload {
  onComplete: (token: ILoginPayload) => void;
}

type CreatorType = "choir" | "music producer" | "singer" | "composer";

interface FormPayloadType {
  name: string;
  email: string;
  country: string;
  address: string;
  phoneNumber: string;
  coverImage: any;
  password: string;
  rptPassword: string;
  type: CreatorType | null;
}

const defaultSignupForm: FormPayloadType = {
  address: "",
  country: "",
  email: "",
  name: "",
  phoneNumber: "",
  coverImage: null,
  password: "",
  rptPassword: "",
  type: null,
};

export const SignuForm = ({ onComplete }: SignupFormPayload) => {
  const imageRef = useRef<HTMLInputElement>(null);
  const [payload, updateForm] = useState({ ...defaultSignupForm });
  const [image, setImage] = useState<any>(null);
  const { showError } = useToastManager();
  const [errors, setErrors] = useState<any>({});

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (payload: any) => choirService.signup(payload),
    mutationKey: ["signup"],
  });

  const handleOpenImageSrc = () => {
    if (imageRef?.current) {
      imageRef.current?.click();
    }
  };

  const handleOnSelectImage = async () => {
    const file = imageRef.current?.files?.[0];
    if (file) {
      const isSupported =
        SupportedImageFormatMap[file.type as SupportedImageFormatType];
      if (!isSupported) {
        alert(
          "This image type is not supported. Reach out to our support for assistance or convert to any of these types: png, jpg, jped, webp"
        );
        return;
      }
      const src = await convertImageFileToBase64Str(
        imageRef.current?.files?.[0] as any
      );
      setImage({
        file,
        src,
        name: file?.name,
      });
      updateForm((p: any) => ({ ...p, coverImage: file }));
    }
  };

  const handleNameChange = (ev: any) => {
    updateForm((p: any) => ({ ...p, name: ev.target.value }));
  };
  const handleEmailChange = (ev: any) => {
    updateForm((p: any) => ({ ...p, email: ev.target.value }));
  };
  const handleCountryChange = (ev: any) => {
    updateForm((p: any) => ({ ...p, country: ev.target.value }));
  };
  const handleAddressChange = (ev: any) => {
    updateForm((p: any) => ({ ...p, address: ev.target.value }));
  };

  const handlePhoneChange = (ev: any) => {
    updateForm((p: any) => ({ ...p, phoneNumber: ev.target.value }));
  };

  const handlePasswordChange = (ev: any) => {
    updateForm((p: any) => ({ ...p, password: ev.target.value }));
  };

  const handleRptPasswordChange = (ev: any) => {
    updateForm((p: any) => ({ ...p, rptPassword: ev.target.value }));
  };

  const handleSubmit = async () => {
    try {
      // add cover image validator
      const errors = createPayloadErrors(payload);
      const hasErrors = Object.keys(errors).length > 0;
      if (hasErrors) {
        setErrors(errors);
        toast("Complete form", { type: "info", hideProgressBar: true });
        return;
      }
      const sanitized = preparePayload(payload);
      const data = (await mutateAsync(sanitized)) as ILoginPayload;
      toast("Congratulations 🎉! Your 2C account has been created.", {
        type: "success",
        hideProgressBar: true,
      });
      onComplete(data);
    } catch (error: any) {
      console.log(error);
      const isDuplicateError =
        error.response.data.errors[0].title === "duplicate-user-error";
      if (isDuplicateError) {
        alert(
          "💡An account with this email already exists. Reset password to recover your account or reach out to support for assistance."
        );
      }
      showError(`Signup Failed.`);
    }
    // async call
  };

  const handleOnClickType = (val: CreatorType) => {
    updateForm((p) => ({ ...p, type: val }));
  };

  return (
    <div className="mt-10 overflow-y-auto pb-10">
      <div className="flex flex-col w-[1000px] mx-auto justify-center">
        <div className="grid grid-cols-2">
          <div className="flex flex-col">
            <Input
              value={payload.name}
              containerProps={{ className: "!my-2" }}
              label="Name (required)"
              onChange={handleNameChange}
              error={errors.name}
            />
            <Input
              value={payload.email}
              containerProps={{ className: "!my-2" }}
              label="Email (required)"
              onChange={handleEmailChange}
              error={errors.email}
              type="email"
            />
            <Input
              value={payload.country}
              containerProps={{ className: "!my-2" }}
              label="Country (required)"
              onChange={handleCountryChange}
              error={errors.country}
            />
            <Input
              value={payload.address}
              containerProps={{ className: "!my-2" }}
              label="Address (required)"
              onChange={handleAddressChange}
              error={errors.address}
            />
            <Input
              value={payload.phoneNumber}
              containerProps={{ className: "!my-2" }}
              label="Phone (+country-code)"
              onChange={handlePhoneChange}
              error={errors.phoneNumber}
              type="tel"
            />
          </div>
          <div className="flex flex-col">
            <Input
              value={payload.password}
              containerProps={{ className: "!my-2" }}
              label="Password (required)"
              onChange={handlePasswordChange}
              error={errors.password && errors.password !== "password-length"}
              type="password"
            />
            <Typography
              variant="small"
              isLabel={!errors.password}
              isError={errors.password === "password-length"}
            >
              Password must be at least 8 characters
            </Typography>
            <Input
              value={payload.rptPassword}
              containerProps={{ className: "!my-2" }}
              label="Repeat Password (required)"
              type="password"
              error={errors.rptPassword}
              onChange={handleRptPasswordChange}
            />
            <Typography variant="p">Cover Image (required)</Typography>
            {RenderCondition(
              !!image,
              <>
                <img
                  src={image?.src}
                  className="mt-5 mb-2 w-[150px] h-[100px] rounded-[3px] object-cover"
                />
                <Typography variant="p">{image?.name}</Typography>
              </>,
              <Typography
                variant="p"
                isLabel={!errors.coverImage}
                isError={errors.coverImage}
              >
                None Selected
              </Typography>
            )}
            <Button
              onClick={handleOpenImageSrc}
              variant="nav"
              className="mt-auto mb-[8px]"
            >
              Select Image
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-x-2">
          <Typography variant="h5" className="inline">
            I am a
          </Typography>
          <Typography
            variant="p"
            isLabel={errors.type !== "Required"}
            isError={errors.type === "Required"}
          >
            select an option below
          </Typography>
          <span>
            <input
              onChange={() => handleOnClickType("choir")}
              checked={payload.type === "choir"}
              type="checkbox"
              id="choir"
              value={"choir"}
            />
            <label
              onClick={() => handleOnClickType("choir")}
              className="pl-2"
              htmlFor="choir"
            >
              choir/choral organization
            </label>
          </span>
          <span>
            <input
              onChange={() => handleOnClickType("music producer")}
              checked={payload.type === "music producer"}
              type="checkbox"
              id="music producer"
              value={"music producer"}
            />
            <label
              onClick={() => handleOnClickType("music producer")}
              className="pl-2"
              htmlFor="music producer"
            >
              Music Producer
            </label>
          </span>
          <span>
            <input
              onChange={() => handleOnClickType("singer")}
              checked={payload.type === "singer"}
              type="checkbox"
              id="singer"
              value={"singer"}
            />
            <label
              onClick={() => handleOnClickType("singer")}
              className="pl-2"
              htmlFor="singer"
            >
              Singer
            </label>
          </span>
          <span>
            <input
              onChange={() => handleOnClickType("composer")}
              checked={payload.type === "composer"}
              type="checkbox"
              id="composer"
              value={"composer"}
            />
            <label
              onClick={() => handleOnClickType("composer")}
              className="pl-2"
              htmlFor="composer"
            >
              Composer
            </label>
          </span>
        </div>
        <Button
          isLoading={isLoading}
          onClick={handleSubmit}
          variant="auth"
          className=" !w-[400px] mt-10"
        >
          Create An Account
        </Button>
        <Typography variant="p" className="mx-auto mt-5">
          Your data is secured and protected 🔐
        </Typography>
      </div>
      <input
        onChange={handleOnSelectImage}
        type="file"
        ref={imageRef}
        className="hidden"
        accept="image/*"
      />
    </div>
  );
};

function createPayloadErrors(payload: FormPayloadType) {
  // can inject field specific validators
  const errors: any = {};
  for (const field in payload) {
    const val = payload[field as keyof FormPayloadType];
    if (field !== "rptPassword") {
      if (field === "email") {
        if (!val) errors.email = "Required";
        const isValid = isValidEmail(val);
        if (!isValid) errors.email = "invalid email";
      } else if (field === "password") {
        if (val.length < 8) errors.password = "password-length";
      } else if (!val) {
        errors[field] = "Required";
      }
    } else {
      if (payload.password && payload.password !== val) {
        errors.rptPassword = "Passwords must be the same";
      }
    }
  }
  return errors as Record<keyof FormPayloadType, string>;
}

function preparePayload(payload: FormPayloadType) {
  const form = new FormData();
  for (const field in payload) {
    if (field !== "rptPassword")
      form.set(field, payload[field as keyof FormPayloadType]);
  }
  return form;
}
