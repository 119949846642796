import styled from "styled-components";
import { Typography, TypographyProps } from "..";
import React from "react";

const base = (props: Omit<TypographyProps, "variant">) =>
  React.createElement(Typography, { variant: "tag", ...props }, "ADMIN");
export const AdminTag = styled(base)`
  padding: 4px;
  border-radius: 3px;
  color: #0091b0;
  border: 1px solid #d9d9d9;
  width: fit-content;
  margin-bottom: 4px;
`;
