import * as React from "react";
import Phase1 from "./Phase1";
import Phase2 from "./Phase2";
import Phase3 from "./Phase3";
import { useDispatch } from "react-redux";
import { toggleCreateSongModal } from "../../../../../redux/slices/createSongSlice";

const CreateNewSongModal: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const [step, setStep] = React.useState(1);

  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  const handleCloseModal = () => {
    dispatch(toggleCreateSongModal());
  };

  const nextStep = () => {
    setStep((prev) => prev + 1);
  };

  switch (step) {
    case 1:
      return <Phase1 onCancel={handleCloseModal} nextStep={nextStep} />;
    case 2:
      return (
        <Phase2
          onCancel={handleCloseModal}
          prevStep={prevStep}
          nextStep={nextStep}
        />
      );
    case 3:
      return <Phase3 onCancel={handleCloseModal} prevStep={prevStep} />;
    default:
      return null;
  }
};

export default CreateNewSongModal;
