import React from "react";
import { Container, Cover, Text } from "./components";

interface CoverTextProps extends React.HTMLAttributes<HTMLDivElement> {
  data: {
    title: string;
    cover: string;
  };
  coverProps?: React.HTMLAttributes<HTMLImageElement>;
}

export const CoverText = ({ data, ...props }: CoverTextProps) => {
  return (
    <Container {...props}>
      <Cover src={data?.cover} {...props?.coverProps} />
      <Text variant="small">{data?.title}</Text>
    </Container>
  );
};
