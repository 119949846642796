import { Button, Typography } from "@chords/design-system";
import React, { useMemo, useState } from "react";
import Input from "../../_shared/components/Input";
import { useToastManager } from "../../_shared/hooks/useToastManager";
import { useMutation } from "react-query";
import { choirService } from "../../../application/choir/choir.service";
import { toast } from "react-toastify";

export const PasswordForm = ({ nextPhase, reset, payload }: any) => {
  const [passes, setPasses] = useState({ password: "", rptPassword: "" });
  const { showError } = useToastManager();

  const errors = useMemo(() => {
    return createInputErrors(passes);
  }, [passes]);

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: ($payload: any) => choirService.resetPassword($payload),
  });

  const handleChangePass = (ev: any) => {
    setPasses((p) => ({ ...p, password: ev.target.value }));
  };
  const handleChangeRptPass = (ev: any) => {
    setPasses((p) => ({ ...p, rptPassword: ev.target.value }));
  };

  const handleProceed = async () => {
    //
    try {
      const hasErrors = Object.keys(errors).length > 0;
      if (hasErrors) {
        toast("Complete form", { type: "info", hideProgressBar: true });
        return;
      }
      const data = await mutateAsync({
        ...payload,
        newPassword: passes.password,
      });
      nextPhase?.(data.token);
    } catch (error) {
      showError("Password reset failed");
    }
  };

  return (
    <div className="mx-auto flex flex-col mt-12 items-center">
      <Typography variant="pMid">Create New Password</Typography>
      <div className="mt-5 w-[350px] py-5 flex flex-col">
        <Input
          className="!w-full"
          containerProps={{ className: "!my-2" }}
          label="New Password"
          type="password"
          onChange={handleChangePass}
          value={passes.password}
          error={errors.password && errors.password !== "password-length"}
        />
        <Typography
          variant="small"
          isLabel={!errors.password}
          isError={errors.password === "password-length"}
        >
          Password must be at least 8 characters
        </Typography>
        <Input
          className="!w-full"
          containerProps={{ className: "!my-2 !mb-10" }}
          label="Repeat Password"
          type="password"
          onChange={handleChangeRptPass}
          value={passes.rptPassword}
          error={errors.rptPassword}
        />
        <Button
          isLoading={isLoading}
          onClick={handleProceed}
          variant="auth"
          className="!w-full mt-auto"
        >
          Reset Password
        </Button>
      </div>
      <Button onClick={reset} variant="link" className="!w-fit mx-auto">
        Change e-mail
      </Button>
    </div>
  );
};

function createInputErrors(payload: any) {
  const errors: any = {};
  if (!payload.password) {
    errors.password = "Required";
  }
  if (payload.password && payload.rptPassword !== payload.password) {
    errors.rptPassword = "Passwords must be the same";
  }
  if (payload.password.length < 8) errors.password = "password-length";

  return errors;
}
