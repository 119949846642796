import React from "react";

import {
  ComposerText,
  Container,
  Cover,
  MetadataContainer,
  TitleText,
} from "./components";
import { Status } from "../atoms";
import { ISongDocument } from "@chords/core";

type SongProps = Omit<React.HTMLAttributes<HTMLDivElement>, "children"> & {
  data: ISongDocument;
};

export const Song = ({ data, ...props }: SongProps) => {
  return (
    <Container onClick={props?.onClick} {...props}>
      <Cover src={data?.coverImage ?? ""} />
      <MetadataContainer>
        <TitleText>{data?.title}</TitleText>
        <ComposerText>{data?.composer}</ComposerText>
        <Status variant={data.status ?? ""} />
      </MetadataContainer>
    </Container>
  );
};
