import { dotenv } from "../envs";

const prod = Object.freeze({
  signin: "https://creatorsignin-7iy64aviza-uc.a.run.app",
  signup: "https://creatorsignup-7iy64aviza-uc.a.run.app",
  readNotification: "https://creatorreadnotification-7iy64aviza-uc.a.run.app",
  updateSong: "https://creatorupdatesong-7iy64aviza-uc.a.run.app",
  deleteSingleSong: "https://creatordeletesinglesong-7iy64aviza-uc.a.run.app",
  resetPassword: "https://creatorresetpassword-7iy64aviza-uc.a.run.app",
  updateConcert: "https://updateconcert-7iy64aviza-uc.a.run.app",
  getProfile: "https://creatorgetprofile-7iy64aviza-uc.a.run.app",
  deleteAlbum: "https://deletealbum-7iy64aviza-uc.a.run.app",
  createConcert: "https://createconcert-7iy64aviza-uc.a.run.app",
  updateProfile: "https://creatorupdateprofile-7iy64aviza-uc.a.run.app",
  deleteAlbumSong: "https://deletealbumsong-7iy64aviza-uc.a.run.app",
  updateAlbum: "https://updatealbum-7iy64aviza-uc.a.run.app",
  updateCoverImage: "https://creatorupdatecoverimage-7iy64aviza-uc.a.run.app",
  verifyPasswordResetCode:
    "https://creatorverifypasswordresetcode-7iy64aviza-uc.a.run.app",
  deleteConcert: "https://deleteconcert-7iy64aviza-uc.a.run.app",
  sendPasswordResetCode:
    "https://creatorsendpasswordresetcode-7iy64aviza-uc.a.run.app",
});

const dev = Object.freeze({
  signin: "http://127.0.0.1:5001/chords-dev-da9da/us-central1/creatorSignin",
  signup: "http://127.0.0.1:5001/chords-dev-da9da/us-central1/creatorSignup",
  readNotification:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/creatorReadNotification",
  updateSong:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/creatorUpdateSong",
  deleteSingleSong:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/creatorDeleteSingleSong",
  resetPassword:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/creatorResetPassword",
  updateConcert:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/updateConcert",
  getProfile:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/creatorGetProfile",
  deleteAlbum: "http://127.0.0.1:5001/chords-dev-da9da/us-central1/deleteAlbum",
  createConcert:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/createConcert",
  updateProfile:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/creatorUpdateProfile",
  deleteAlbumSong:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/deleteAlbumSong",
  updateAlbum: "http://127.0.0.1:5001/chords-dev-da9da/us-central1/updateAlbum",
  updateCoverImage:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/creatorUpdateCoverImage",
  verifyPasswordResetCode:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/creatorVerifyPasswordResetCodep",
  deleteConcert:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/deleteConcert",
  sendPasswordResetCode:
    "http://127.0.0.1:5001/chords-dev-da9da/us-central1/creatorSendPasswordResetCode",
});
let ApiStore: typeof dev | typeof prod;
const useDev = dotenv.appEnv !== "production";
if (useDev) ApiStore = dev;
else {
  ApiStore = prod;
}

export { ApiStore };
