import axios from "axios";
import { ApiStore } from "../../infra/api/store";
import { HttpClient } from "../../infra/httpClient";

const getChoirProfile = async (id: string) => {
  const data = await axios.get(`${ApiStore.getProfile}/?id=${id}`);
  return data.data.data;
};

const updateChoirProfile = async (id: string, params: any) => {
  await HttpClient.patch(`${ApiStore.updateProfile}?creatorId=${id}`, params);
};

const updateChoirCoverimage = async (id: string, formData: FormData) => {
  await axios.patch(`${ApiStore.updateCoverImage}?creatorId=${id}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const readNotification = (notificationId: string, choirId: string) => {
  //
  return axios.patch(
    `${ApiStore.readNotification}?creatorId=${choirId}&notificationId=${notificationId}`,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const updatechoirAlbumMeta = async (
  choirId: string,
  albumId: string,
  payload: any
) => {
  await axios.patch(
    `${ApiStore.updateAlbum}?creatorId=${choirId}&albumId=${albumId}`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

const updateSingleSong = async (
  choirId: string,
  songId: string,
  payload: any
) => {
  console.log(payload);
  await axios.patch(
    `${ApiStore.updateSong}?creatorId=${choirId}&songId=${songId}`,
    payload,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

const removeSingleSong = (choirId: string, songId: string) => {
  return HttpClient.delete(
    `${ApiStore.deleteSingleSong}?creatorId=${choirId}&songId=${songId}`
  );
};

const removeAlbumSong = async (
  choirId: string,
  albumId: string,
  songId: string
) => {
  console.log(albumId, songId);
  await HttpClient.delete(
    `${ApiStore.deleteAlbumSong}?creatorId=${choirId}&albumId=${albumId}&songId=${songId}`
  );
};

const removeAlbum = async (choirId: string, albumId: string) => {
  await HttpClient.delete(
    `${ApiStore.deleteAlbum}/?creatorId=${choirId}&albumId=${albumId}`
  );
};

const signup = async (payload: any) => {
  const res = await axios.post(ApiStore.signup, payload, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data.data;
};

const signin = async (payload: any) => {
  const res = await axios.post(ApiStore.signin, payload);
  return res.data.data;
};

const passResetVerifyAccount = async (email: string) => {
  await HttpClient.post(ApiStore.sendPasswordResetCode, { email });
};

const resetPassword = async (payload: {
  email: string;
  code: string;
  newPassword: string;
}) => {
  const res = await HttpClient.post(ApiStore.resetPassword, {
    ...payload,
    password: payload.newPassword,
  });
  return res.data.data;
};

const verifyResetCode = async (payload: { email: string; code: string }) => {
  await HttpClient.post(ApiStore.verifyPasswordResetCode, payload);
};

export const choirService = {
  signup,
  verifyResetCode,
  passResetVerifyAccount,
  resetPassword,
  signin,
  getChoirProfile,
  updateChoirProfile,
  updateChoirCoverimage,
  readNotification,
  updatechoirAlbumMeta,
  updateSingleSong,
  removeAlbumSong,
  removeAlbum,
  removeSingleSong,
} as const;
