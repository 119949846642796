import {
  resetSong,
  toggleCreateSongModal,
} from "../../../redux/slices/createSongSlice";
import { useCreateSongState } from "./useCreateSongState";
import { useAppDispatch } from "./useRedux";

/**
 *
 * @returns
 * - open -> boolean;
 * - toggleOpen -> fn;
 * - data -> songUpload
 *
 * Hook to interact with the AlbumUpload global state
 */
export const useSongModalState = () => {
  const dispatch = useAppDispatch();
  const { open, data } = useCreateSongState();

  const toggleOpen = () => {
    dispatch(toggleCreateSongModal());
  };

  const reset = () => {
    dispatch(resetSong());
  };

  return {
    open,
    toggleOpen,
    data,
    reset,
  };
};
