import { NavigateOptions, To, useNavigate } from "react-router-dom";

/**
 *
 * @param to
 * @param options
 * a navigation hook that reloads a page after navigation.
 */
export const useNavigationWithReload = () => {
  const $nav = useNavigate();
  const nav = (to: To, options?: NavigateOptions) => {
    $nav(to, options);
    window.location.reload();
  };
  return nav;
};
