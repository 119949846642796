import axios from "axios";
import { ApiStore } from "../../infra/api/store";

const createConcert = async (id: string, params: any) => {
  await axios.post(`${ApiStore.createConcert}?creatorId=${id}`, params, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

const editConcert = async (concertId: string, choirId: string, params: any) => {
  await axios.patch(
    `${ApiStore.updateConcert}?creatorId=${choirId}&concertId=${concertId}`,
    params
  );
};

const deleteConcert = async (concertId: string, choirId: string) => {
  await axios.delete(
    `${ApiStore.deleteConcert}?creatorId=${choirId}&concertId=${concertId}`
  );
};

export const concertService = {
  createConcert,
  editConcert,
  deleteConcert,
} as const;
