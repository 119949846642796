import React from "react";
import { SongStatus } from "admin";
import { Text } from "./components";

interface StatusProps {
  variant: SongStatus;
}

export const Status = ({ variant }: StatusProps) => {
  return <Text status={variant}>{variant.toUpperCase()}</Text>;
};
