import { Route } from "./modules/_shared/@types";
import { ICONS } from "./modules/_shared/utils/constants";

export const routes: Route[] = [
  {
    icon: ICONS.dashboard,
    routeName: "choir board",
    link: "/choirboard",
  },
  {
    icon: ICONS.upload,
    routeName: "uploads",
    link: "/uploads",
  },
  {
    icon: ICONS.upload,
    routeName: "concert",
    link: "/concert",
  },
  {
    icon: ICONS.settings,
    routeName: "settings",
    link: "/settings",
  },
];
