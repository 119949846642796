import { query, onSnapshot, where, FieldPath } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Repositories } from "../../../infra/repositories";
import { useAuth } from "../../_shared/hooks/useAuth";

export const useAlbum = (albumId: string) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState(false);
  const { id } = useAuth().user!;

  useEffect(() => {
    if (!albumId) {
      setLoading(false);
      setError(false);
      setData(null);
      return;
    }
    setLoading(true);
    setError(false);
    const qRef = query(
      Repositories.album,
      where("__name__", "==", albumId),
      where(new FieldPath("creatorSummary", "id"), "==", id)
    );
    const unSub = onSnapshot(
      qRef,
      (observer) => {
        const res = observer.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        if (res.length === 1) setData(res[0]);
      },
      (err) => {
        console.log("subscribe to album error");
        console.log(err);
        console.log("-----");
        console.log("-----");
        setLoading(false);
        setError(true);
      }
    );

    return function () {
      unSub?.();
    };
  }, [id]);

  return {
    loading,
    error,
    data,
  };
};
