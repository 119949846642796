import React, { useCallback, useEffect, useState } from "react";
import Input from "../../Input";
import { Typography } from "@chords/design-system";
import { PhaseContainer } from "./PhaseContainer";
import { defaultSong } from "./root";
import { useCreateSongState } from "../../../hooks/useCreateSongState";
import { syncCreateSongData } from "../../../../../redux/slices/createSongSlice";
import { useAppDispatch } from "../../../hooks/useRedux";
import { Genre, Themes } from "../../../../../application/song";

interface IPhase1Props {
  nextStep: () => void;
  onCancel?: () => void;
}

const Phase1: React.FunctionComponent<IPhase1Props> = ({
  nextStep,
  onCancel,
}) => {
  //
  const { data } = useCreateSongState();
  const dispatch = useAppDispatch();
  const [localPayload, setLocalPayload] = useState(defaultSong);
  const [errors, setErrors] = useState<any>({});

  const isSelectedTheme = useCallback(
    (theme: any) => {
      return localPayload.theme?.includes(theme);
    },
    [localPayload.theme]
  );

  const isSelectedGenre = useCallback(
    (genre: any) => {
      return localPayload.genre === genre;
    },
    [localPayload.genre]
  );

  const handleOnUpdateGenre = (item: any) => {
    setLocalPayload((p) => {
      const local = { ...p };
      local.genre = item;
      return local;
    });
  };

  const addToTheme = (item: any) => {
    const theme = [...localPayload.theme!];
    theme.push(item);
    setLocalPayload({ ...localPayload, theme });
  };

  const removeTheme = (item: any) => {
    const theme = localPayload.theme?.filter((thm) => thm !== item);
    setLocalPayload({ ...localPayload, theme });
  };

  const handleToNext = () => {
    const errs = createErrors(localPayload);
    const hasErrs = Object.keys(errs).length > 0;
    if (hasErrs) {
      return setErrors(errs);
    }
    dispatch(syncCreateSongData(localPayload));
    nextStep();
  };

  useEffect(() => {
    setLocalPayload(data);
  }, [data]);

  return (
    <PhaseContainer
      header="Create New Song"
      onClickCancel={onCancel}
      onClickNext={handleToNext}
    >
      <form className="flex flex-col h-[90%] overflow-auto pb-2">
        <div className="flex flex-col">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 ">
            <div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Title (required)"
                  name={"title"}
                  type={"text"}
                  value={localPayload.title}
                  placeholder={"Type song title"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setLocalPayload((p) => ({ ...p, title: e.target.value }));
                  }}
                  error={errors.title}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Composer (required)"
                  name={"composer"}
                  type={"text"}
                  error={errors.composer}
                  value={localPayload.composer!}
                  placeholder={"Eg. G.F Handel"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setLocalPayload((p) => ({
                      ...p,
                      composer: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Conductor (optional)"
                  name={"conductor"}
                  type={"text"}
                  value={localPayload.conductor!}
                  placeholder={"Conductor name"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setLocalPayload((p) => ({
                      ...p,
                      conductor: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Soloists (optional)"
                  name={"soloists"}
                  type={"text"}
                  value={localPayload.soloists}
                  placeholder={
                    "comma-separated list of featured soloists. Eg: Soloist 1, Soloist 2"
                  }
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setLocalPayload((p) => ({
                      ...p,
                      soloists: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Producer (optional)"
                  name={"producer"}
                  type={"text"}
                  value={localPayload.producedBy!}
                  placeholder={"Eg: Chords Productions"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setLocalPayload((p) => ({
                      ...p,
                      producedBy: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Composed date (optional)"
                  name={"composedDate"}
                  type={"date"}
                  value={localPayload.composedDate}
                  placeholder={"Eg: 2023-01-03..."}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setLocalPayload((p) => ({
                      ...p,
                      composedDate: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Description (optional)"
                  name={"description"}
                  type={"text"}
                  value={localPayload.description!}
                  placeholder={
                    "An inspiration behind the song or any relevant info"
                  }
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setLocalPayload((p) => ({
                      ...p,
                      description: e.target.value,
                    }));
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Keywords (optional)"
                  name={"related keywords"}
                  type={"text"}
                  value={localPayload.relatedKeywords}
                  placeholder={
                    "comma-separated keywords to reference this song"
                  }
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setLocalPayload((p) => ({
                      ...p,
                      relatedKeywords: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
            <div>
              <div className="w-fit flex flex-col gap-4">
                <Typography
                  isLabel={!errors.genre}
                  isError={errors.genre}
                  variant="pMid"
                >
                  Genre (required - select one)
                </Typography>
                <div className="flex gap-3 flex-wrap">
                  {Genre.map((item) => {
                    const selected = isSelectedGenre(item);
                    return (
                      <Typography
                        key={item}
                        variant="small"
                        className={`p-1 border-[1px] hover:cursor-pointer ${
                          selected
                            ? "border-1 border-chordsDark border-solid"
                            : ""
                        }`}
                        onClick={() => handleOnUpdateGenre(item)}
                      >
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              </div>
              <div className="w-fit flex mt-5 flex-col gap-4">
                <Typography isLabel variant="pMid">
                  Theme (recommended: at least 1)
                </Typography>
                <div className="flex gap-3 flex-wrap">
                  {Themes.map((item) => {
                    const selected = isSelectedTheme(item);

                    return (
                      <Typography
                        key={item}
                        variant="small"
                        className={`p-1 border-[1px] hover:cursor-pointer ${
                          selected
                            ? "border-[1px] border-chordsDark border-solid"
                            : ""
                        }`}
                        onClick={() => {
                          if (selected) return removeTheme(item);
                          return addToTheme(item);
                        }}
                      >
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              </div>
              <div className="max-w-[24rem] mt-5 w-full">
                <Input
                  label="References to Other Hymns (optional) "
                  name={"hymn refs"}
                  type={"text"}
                  value={localPayload.hymnRefs}
                  placeholder={"eg: CH113, MHB432"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setLocalPayload((p) => ({
                      ...p,
                      hymnRefs: e.target.value,
                    }));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </PhaseContainer>
  );
};

export default Phase1;

function createErrors(payload: any) {
  const errors: any = {};
  if (!payload.title) errors.title = "Required";
  if (!payload.composer) errors.composer = "Required";
  if (!payload.genre) errors.genre = "Required";
  return errors;
}
