import React from "react";
import "./App.css";
import Header from "./modules/_shared/components/Header";
import Sidebar from "./modules/_shared/components/Sidebar";
import { Outlet } from "react-router-dom";
import { UploadsQueueComponent } from "./modules/_shared/components/UploadsStack";
import { UploadSongRoot } from "./modules/_shared/components/modal/singleSongUpload/root";
import AlbumUploadModal from "./modules/_shared/components/modal/albumUpload/AlbumUpload";
import { useAlbumModalState } from "./modules/_shared/hooks/useAlbumModalState";
import { useSongModalState } from "./modules/_shared/hooks/useSongModalState";

function App() {
  const { open: openCreateAlbum } = useAlbumModalState();
  const { open: openCreateSong } = useSongModalState();

  return (
    <div className="h-screen flex flex-col pb-[50px]">
      <Header />
      <div className="flex flex-grow">
        <Sidebar />
        <div className="ml-[13rem] mt-[70px] flex-grow overflow-y-auto  w-full h-full">
          <Outlet />
          {openCreateSong && <UploadSongRoot />}
          {openCreateAlbum && <AlbumUploadModal />}
        </div>
      </div>
      <UploadsQueueComponent />
    </div>
  );
}

export default App;
