const parse = (meta: any) => {
  const form = new FormData();
  form.set("title", meta?.title);
  form.set("description", meta?.description);
  if (typeof meta?.coverImage !== "string") {
    form.set("coverImage", meta?.coverImage);
  }
  form.set("location", meta?.location);
  form.set("liveStreamLink", meta?.liveStreamLink);
  const dateAndTime = getDateAndTime(meta.date, meta.time);
  form.set("dueDate", dateAndTime);
  form.set("creatorSummary", meta.creatorSummary);

  return form;
};

const validate = (meta: any) => {
  // create concert validation
};

export const prepareConcertParams = {
  validate,
  parse,
  getDateAndTime,
} as const;

function getDateAndTime(date: string, time: string) {
  const [year, month, day] = date.split("-");
  const [h, m] = time.split(":");
  let hrs = parseInt(h);
  const mins = parseInt(m);
  if (time.toLowerCase().endsWith("pm") && hrs !== 12) {
    hrs += 12;
  } else if (time.toLowerCase().endsWith("am") && hrs === 12) {
    hrs = 0;
  }
  const formattedDate = new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    hrs,
    mins
  );
  return formattedDate.getTime().toString();
}
