import { Button, Typography } from "@chords/design-system";
import React, { useState } from "react";
import { VerifyAccount } from "./verifyAccount";
import { PasswordForm } from "./passwordForm";
import { useToastManager } from "../../_shared/hooks/useToastManager";
import { useMutation } from "react-query";
import { choirService } from "../../../application/choir/choir.service";
import { useNavigationWithReload } from "../../_shared/hooks/useAppNavigation";
import { useNavigate } from "react-router-dom";
import { isValidEmail } from "../../../lib/helper/validators";
import { toast } from "react-toastify";

const defPayload = {
  code: "",
  email: "",
  token: "",
};

export const ResetPassword = () => {
  return (
    <div className="w-screen h-screen">
      <header className="flex h-[80px] bg-black">
        <div className="container flex items-center">
          <Button
            variant="auth"
            className="!border-[1px] !border-[#fff] !border-solid ml-auto"
          >
            Sign up
          </Button>
        </div>
      </header>
      <main className="container flex flex-col mx-auto">
        <Typography variant="h2" className="mx-auto mt-10">
          Stay secured on Chords 🔐
        </Typography>
        <Flow />
      </main>
    </div>
  );
};

const Flow = () => {
  const [phase, setPhase] = useState(0);
  const [payload, setPayload] = useState({ ...defPayload });
  const nav = useNavigate();

  const nextPhase = () => setPhase((p) => p + 1);

  const onSubmitEmail = (email: string) => {
    //
    setPayload((p) => ({ ...p, email }));
    nextPhase();
  };

  const onSubmitResetCode = (code = "") => {
    setPayload((p) => ({ ...payload, code }));
    nextPhase();
  };

  const onPasswordReset = (token: string) => {
    setPayload((p) => ({ ...p, token }));
    nextPhase();
  };

  const reset = () => {
    setPayload({ ...defPayload });
    setPhase(0);
  };

  const toSignin = () => {
    nav("/signin", { state: { token: payload.token } });
  };

  if (phase === 0) return <Email onComplete={onSubmitEmail} />;
  if (phase === 1)
    return (
      <Code payload={payload} nextPhase={onSubmitResetCode} prevPhase={reset} />
    );

  if (phase === 2)
    return (
      <PasswordForm
        nextPhase={onPasswordReset}
        reset={reset}
        payload={payload}
      />
    );

  return (
    <div className="mx-auto flex flex-col gap-y-4 items-center">
      <Typography variant="p" className=" mt-20">
        Your new password is live. Stay safe 👍
      </Typography>
      <Button onClick={toSignin} className="!w-[250px]" variant="auth">
        Signin to 2C
      </Button>
    </div>
  );
};

const Email = ({ onComplete }: any) => {
  const [email, setEmail] = useState("");
  const { showError } = useToastManager();
  const [error, setError] = useState("");

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () => choirService.passResetVerifyAccount(email),
  });

  const handleEmailChange = (ev: any) => {
    setEmail(ev.target.value);
  };

  const onProceed = async () => {
    try {
      const isValid = isValidEmail(email);
      if (!isValid) {
        setError("invalid email");
        return;
      }
      if (error) setError("");
      await mutateAsync();
      onComplete?.(email);
    } catch (error: any) {
      showError("Account Verification failed");
    }
  };

  return (
    <VerifyAccount
      inputProps={{
        value: email,
        onChange: handleEmailChange,
        label: "Email",
        type: "email",
        error,
      }}
      proceedButtonProps={{
        onClick: onProceed,
        isLoading: isLoading,
      }}
      label="Identify your account with your e-mail"
    />
  );
};

const Code = ({ nextPhase, prevPhase, payload }: any) => {
  const [code, setCode] = useState("");
  const { showError } = useToastManager();

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: () => choirService.verifyResetCode({ ...payload, code }),
  });

  const handleOnChange = (ev: any) => {
    setCode(ev.target.value);
  };

  const onProceed = async () => {
    try {
      await mutateAsync();
      nextPhase?.(code);
    } catch (error: any) {
      showError("Account Verification failed");
    }
  };

  return (
    <VerifyAccount
      inputProps={{
        value: code,
        onChange: handleOnChange,
        label: "Reset code",
      }}
      proceedButtonProps={{
        onClick: onProceed,
        isLoading: isLoading,
      }}
      cancelText="Change Email"
      cancelButtonProps={{ onClick: prevPhase }}
      label="Reset code has been sent to your e-mail."
    />
  );
};
