import React from "react";
import { Button, CoverText, Song, Typography } from "@chords/design-system";
import { RenderCondition } from "@chords/design-system/render";
import { useAnalytics } from "./hooks/useAnalytics";
import { useLatestSongs } from "./hooks/useLatestSongs";
import { useLatestAlbums } from "./hooks/useLatestAlbums";
import { useNavigate } from "react-router-dom";

const ChoirBoard: React.FunctionComponent = () => {
  const nav = useNavigate();
  const { data: analytics } = useAnalytics();
  const { data: latestSongs } = useLatestSongs();
  const { data: latestAlbums } = useLatestAlbums();

  const handleOnClickGoToAlbums = () => {
    //
    nav(`/uploads#album-uploads`, { state: { id: "album-uploads" } });
  };

  const handleOnClickGoToSongsUploads = () => {
    //
    nav(`/uploads#single-uploads`, { state: { id: "single-uploads" } });
  };

  const handleLatestChoirAlbum = (album: any) => {
    nav(`/album/${album.id}`);
  };

  return (
    <section className="w-full px-8 pb-8 pt-5">
      <section className="mt-8 w-full h-[500px] flex gap-20 2xl:gap-20">
        <div className="flex flex-col h-full gap-10 w-full p-6 flex-shrink-0  flex-grow-0 shadow-xl max-w-[400px]">
          <div className="border-b-[2px] flex flex-col gap-4 pb-3 border-b-[#f1f1f1]">
            <Typography variant="h6">Your Analytics</Typography>
            <div className="flex flex-col">
              <div className="">
                <Typography
                  variant="small"
                  className="inline text-[#707070] align-top relative top-[12px]"
                >
                  Streams
                </Typography>
                <Typography variant="h2" className="inline align-super pl-1">
                  {analytics.streams ?? 0}
                </Typography>
                <Typography
                  variant="h3"
                  className="inline relative px-1 align-baseline"
                >
                  /
                </Typography>
                <Typography variant="h3" className="inline align-sub">
                  {analytics.songs ?? 0}
                </Typography>
                <Typography
                  variant="small"
                  className="inline  align-bottom relative bottom-[12px] left-1"
                >
                  Song(s)
                </Typography>
              </div>
            </div>
          </div>
          <div className="border-b-[2px] flex flex-col gap-4 pb-8 border-b-[#f1f1f1]">
            <Typography variant="pMid" className="text-[#707070]">
              Breakdown
            </Typography>
            <div className="flex flex-col gap-3">
              <div className="flex justify-between">
                <Typography variant="lStatus" status="pending">
                  Pending Uploads
                </Typography>
                <Typography variant="p">{analytics?.pending ?? "-"}</Typography>
              </div>
              <div className="flex justify-between">
                <Typography variant="lStatus" status="approved">
                  Approved Uploads
                </Typography>
                <Typography variant="p">
                  {analytics?.approved ?? "-"}
                </Typography>
              </div>
              <div className="flex justify-between">
                <Typography variant="lStatus" status="rejected">
                  Rejected Uploads
                </Typography>
                <Typography variant="p">
                  {analytics?.rejected ?? "-"}
                </Typography>
              </div>
            </div>
          </div>
          {/* <Button
            onClick={handleOnClickAnalyticsDetails}
            variant="link"
            className="!mt-auto"
          >
            Details
          </Button> */}
        </div>
        <div className="h-full flex p-6 flex-col shadow-xl gap-8 w-[30rem]">
          <Typography variant="h6" className="">
            Latest Song uploads
          </Typography>
          <div className="flex flex-col gap-5 overflow-y-auto">
            {RenderCondition(
              (latestSongs.length ?? 0) > 0,
              latestSongs.map((song: any) => {
                return <Song data={song as any} key={song.id} />;
              }),
              <Typography variant="h4" isLabel>
                No Data
              </Typography>
            )}
          </div>
          <Button
            onClick={handleOnClickGoToSongsUploads}
            variant="auth"
            className="flex-shrink-0 mt-auto"
          >
            Go to Song uploads
          </Button>
        </div>
      </section>
      <section className="flex flex-col mt-16 shadow-xl p-6 w-[60rem]">
        <Typography variant="h6" className="">
          Latest Album uploads
        </Typography>
        <div className="flex flex-wrap gap-2 gap-x-5 min-h-[150px] mt-5">
          {RenderCondition(
            (latestAlbums?.length ?? 0) > 0,
            latestAlbums.map((album: any) => {
              return (
                <CoverText
                  onClick={() => handleLatestChoirAlbum(album)}
                  coverProps={{
                    className: "!h-28 !w-28",
                    style: { marginTop: 0 },
                  }}
                  className="!mt-0 !w-fit !h-fit -2 hover:cursor-pointer p-2 rounded-sm hover:bg-chordsLightBg"
                  data={{ cover: album.coverImage, title: album.title }}
                  key={album.id}
                />
              );
            }),
            <Typography variant="h4" isLabel>
              No Data
            </Typography>
          )}
        </div>
        <Button
          onClick={handleOnClickGoToAlbums}
          variant="auth"
          className="mt-5"
        >
          Go to Album uploads
        </Button>
      </section>
    </section>
  );
};

export default ChoirBoard;
