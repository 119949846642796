import styled from "styled-components";

export const FormInput = styled.input`
  width: 90%;
  border-radius: 1px;
  border: 1px solid #d9d9d9;
  height: 40px;
  padding: 4px 8px;
  font-size: 13px;
`;
