import { useRef } from "react";
import {
  ToastContent,
  ToastOptions,
  TypeOptions,
  UpdateOptions,
  toast,
} from "react-toastify";
import { Type } from "react-toastify/dist/utils";

export const useToastManager = () => {
  const toastRef = useRef<any>(null);

  const show = (content: ToastContent, options?: ToastOptions) => {
    toastRef.current = toast(content, options);
  };

  const showError = (content: ToastContent, options?: ToastOptions) => {
    show(content, { type: "error" });
  };

  const showSuccess = (content: ToastContent, options?: ToastOptions) => {
    show(content, { type: "success", ...options });
  };

  const update = (content: ToastContent, type?: TypeOptions) => {
    if (toastRef.current) {
      toast.update(toastRef.current, {
        render: content,
        type,
        autoClose: 1500,
        isLoading: false,
      });
    }
  };

  return { showError, showSuccess, show, update };
};
