import {
  onSnapshot,
  query,
  where,
  orderBy,
  limit,
  FieldPath,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Repositories } from "../../../infra/repositories";
import { useAuth } from "../../_shared/hooks/useAuth";

export const useUpNextConcert = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState(false);
  const { id } = useAuth().user!;

  useEffect(() => {
    setLoading(true);
    setError(false);
    const qRef = query(
      Repositories.concert,
      where(new FieldPath("creatorSummary", "id"), "==", id),
      orderBy("dueDate", "desc"),
      limit(1)
    );
    const unSub = onSnapshot(
      qRef,
      (observer) => {
        if (observer.docs.length > 0) {
          const doc = observer.docs[0];
          const isUpcoming = doc.data().dueDate > new Date().getTime();
          if (isUpcoming) {
            setData({ ...doc.data(), id: doc.id });
          } else setData(null);
        }
        setLoading(false);
      },
      (err) => {
        console.log("-------UpNext concert -------");
        console.log(err);
        console.log("-------");
        setLoading(false);
        setError(true);
      }
    );

    return function () {
      unSub?.();
    };
  }, [id]);

  return {
    loading,
    error,
    data,
  };
};
