import React, { useEffect, useMemo, useState } from "react";
import { NotificationType } from "./type";
import { Typography } from "@chords/design-system";
import moment from "moment";

interface NotificationItemProps extends React.HTMLAttributes<HTMLDivElement> {
  data: any;
}

const notificationColorSchemeMap = Object.freeze({
  upload: "#393E6F",
  auth: "#932B77",
  account: "#044A42",
  info: "#2C061F",
});

export const NotificationItem = ({
  data: notification,
  onClick,
}: NotificationItemProps) => {
  const [updateCount, forceUpdate] = useState(0);

  const time = useMemo(() => {
    return moment(new Date(notification.createdAt))
      .startOf("minutes")
      .fromNow(); // add logic to end the time update on the notification component
  }, [notification, updateCount]);

  useEffect(() => {
    let timer: NodeJS.Timer;
    timer = setInterval(() => {
      forceUpdate((p) => ++p);
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div
      onClick={(e: any) => {
        e.target = { value: notification };
        onClick?.(notification);
      }}
      className="w-full h-14 p-1 px-3 flex gap-x-4 items-center hover:bg-chordsLightBg hover:cursor-pointer"
    >
      <div
        style={{
          backgroundColor:
            notificationColorSchemeMap[
              notification.type as keyof typeof notificationColorSchemeMap
            ],
        }}
        className="relative h-10 w-10 rounded-full border-solid border-[1px] flex items-center justify-center"
      >
        <NotificationType
          className="text-[22px] text-chordsDark"
          type={notification.type}
        />
        <div
          style={{
            backgroundColor: notification.read ? "#d1d1d1" : "#5ca52d",
          }}
          className="absolute w-[10px] h-[10px] rounded-full bottom-0 right-0"
        />
      </div>
      <div className="flex flex-col justify-between gap-y-1">
        <Typography variant="pMid">{notification.data}</Typography>
        <div className="flex items-center gap-x-2">
          <Typography className="!text-[#a9a9a9]" variant="small">
            {time}
          </Typography>
          <Typography className="!text-[#a9a9a9]" variant="small">
            {notification.type}
          </Typography>
        </div>
      </div>
    </div>
  );
};
