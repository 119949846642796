import { IAlbumBase, ISongBase } from "@chords/core";
import { createSlice } from "@reduxjs/toolkit";

export interface UploadAlbumPayload
  extends Omit<Partial<IAlbumBase>, "relatedKeywords"> {
  relatedKeywords: string;
  coverImage: any;
}

export interface UploadAlbumSongPayload
  extends Omit<
    ISongBase,
    "soloists" | "relatedKeywords" | "coverImage" | "hymnRefs" | "composedDate"
  > {
  soloists: string;
  relatedKeywords: string;
  coverImage: any;
  audioSrc: any;
  key: string | number;
  hymnRefs: string;
  composedDate: string;
}

interface UploadAlbumSongs {
  [key: number]: UploadAlbumSongPayload;
}

export const defaultAlbum: Partial<UploadAlbumPayload> = {
  title: "",
  relatedKeywords: "",
  description: "",
  composedDate: "",
  coverImage: null,
  theme: [],
};

export const defaultAlbumSong: Partial<UploadAlbumSongPayload> = {
  title: "",
  soloists: "",
  audioSrc: null,
  coverImage: null,
  composedDate: "",
  composer: "",
  conductor: "",
  genre: undefined,
  theme: [],
  hymnRefs: "",
  description: "",
  producedBy: "",
  relatedKeywords: "",
};

interface CreateSongFlowState {
  meta: Partial<UploadAlbumPayload>;
  songs: UploadAlbumSongs;
  open: boolean;
  isLoading: boolean;
  isError: boolean;
}

const initialState: CreateSongFlowState = {
  meta: defaultAlbum,
  isError: false,
  isLoading: false,
  songs: {},
  open: false,
};

export const createAlbumSlice = createSlice({
  name: "createAlbum",
  initialState,
  reducers: {
    toggleOpen: (state) => {
      state.open = !state.open;
    },
    syncData: (state, { payload }) => {
      state.meta = payload;
    },
    reset: (state) => {
      state.meta = defaultAlbum;
      state.songs = [];
      state.isError = false;
      state.isLoading = false;
    },
    removeSong: (state, { payload }) => {
      delete state.songs[payload as number];
    },
    addSong: (state, { payload }) => {
      state.songs[payload.key] = payload;
    },
  },
});

export const {
  syncData: syncCreateAlbumData,
  toggleOpen: toggleCreateAlbumModal,
  addSong,
  removeSong,
  reset: resetAlbum,
} = createAlbumSlice.actions;

export const { reducer: createAlbumReducer } = createAlbumSlice;
