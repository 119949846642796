import { FieldPath, onSnapshot, query, where } from "firebase/firestore";
import { Repositories } from "../../../infra/repositories";

import { useEffect, useState } from "react";
import { useAuth } from "../../_shared/hooks/useAuth";

export const useSingleSongsUploads = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const [error, setError] = useState(false);
  const { id } = useAuth().user!;

  useEffect(() => {
    const qRef = query(
      Repositories.song,
      where(new FieldPath("creatorSummary", "id"), "==", id),
      where("type", "==", "single")
    );
    const unSub = onSnapshot(
      qRef,
      (observer) => {
        const docs = observer.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setData(docs);
        setLoading(false);
      },
      (err) => {
        setError(true);
        setLoading(false);
      }
    );
    return function () {
      unSub?.();
    };
  }, []);

  return {
    data,
    loading,
    error,
  };
};
