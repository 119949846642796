import React, { useMemo, useState } from "react";
import { AlbumMetaModal } from "./AlbumMeta";
import { UploadAlbumPayload } from "../../../../../redux/slices/createAlbum";
import { choirService } from "../../../../../application/choir/choir.service";
import { useAuth } from "../../../hooks/useAuth";
import { useToastManager } from "../../../hooks/useToastManager";

interface EditAlbumProps {
  meta: Partial<UploadAlbumPayload> & { id: string };
  onEdit?: () => void;
  onCancel?: () => void;
}

export const EditAlbumMeta = ({ meta, onEdit, onCancel }: EditAlbumProps) => {
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const { show } = useToastManager();

  const editMeta = useMemo(() => {
    if (meta) {
      return createMetaAdapter(meta);
    }
  }, [meta]);

  const handleNext = async ({ target }: any) => {
    try {
      setIsLoading(true);
      const diffPayload = createMetaDiff(editMeta, target.value);
      const editPayload = createFormData(diffPayload);
      if (Array.from(editPayload.keys()).length > 0) {
        await choirService.updatechoirAlbumMeta(user!.id, meta.id, editPayload);
        onEdit?.();
      } else onCancel?.();
    } catch (error: any) {
      show("Failed to update album", { type: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AlbumMetaModal
      meta={editMeta as any}
      header="Edit Album Meta"
      nextButtonLabel="Edit"
      onClickCancel={onCancel}
      onClickNext={handleNext}
      nextbuttonProps={{ isLoading }}
    />
  );
};

function createMetaAdapter(meta: Partial<UploadAlbumPayload>) {
  const coverName = `${meta.title} cover`;
  return { ...meta, coverImage: { src: meta.coverImage, name: coverName } };
}

function createMetaDiff(oldMeta: any, newMeta: any) {
  //
  const diff: any = {};
  for (const field in oldMeta) {
    const oldVal = oldMeta[field];
    const respectiveNewVal = newMeta[field];
    if (respectiveNewVal && oldVal !== respectiveNewVal) {
      diff[field] = respectiveNewVal;
    }
  }
  return diff;
}

function createFormData(data: any) {
  // move to util

  const form = new FormData();
  for (const field in data) {
    const val = data[field];

    if (Array.isArray(val)) {
      for (const v of val) {
        form.append(field, v);
      }
    } else {
      if (field === "coverImage") {
        form.set(field, val.file);
      } else {
        form.set(field, val);
      }
    }
  }

  return form;
}
