import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, FormInput, Typography } from "@chords/design-system";
import { Icon } from "@iconify/react";
import { EditSingleInputModal } from "./components/EditSingleInputModal";
import { RenderIf } from "@chords/design-system/render";
import { useMutation, useQuery } from "react-query";
import { choirService } from "../../application/choir/choir.service";
import { useAuth } from "../_shared/hooks/useAuth";
import { useToastManager } from "../_shared/hooks/useToastManager";
import { useAppDispatch } from "../_shared/hooks/useRedux";
import { authActions } from "../../redux/slices/auth.slice";

type EditMode = "close" | "name" | "email" | "country" | "address" | "password";

const Settings: React.FunctionComponent = () => {
  const { user } = useAuth();
  const { showSuccess, showError } = useToastManager();
  const dispatch = useAppDispatch();
  const id = user?.id ?? "";
  const dialogRef = useRef<HTMLDialogElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [editMode, setEditMode] = useState<EditMode>("name");
  const { data, refetch: fetchChoirProfile } = useQuery({
    queryKey: `choir-data-${id}`,
    queryFn: () => choirService.getChoirProfile(id),
    enabled: false,
  });

  const { mutateAsync, isLoading: isUpdatingMeta } = useMutation({
    mutationFn: (params) => choirService.updateChoirProfile(id, params),
    onSuccess: () => fetchChoirProfile(),
  });

  const { mutate: editCover, isLoading: isUpdatingCover } = useMutation({
    mutationFn: (params) =>
      choirService.updateChoirCoverimage(id, params as any),
    onSuccess: () => fetchChoirProfile(),
    onError: () => showError("Failed to update cover image"),
  });

  const withCloseModal = useCallback((cb?: any) => {
    return async function (...args: any[]) {
      await cb?.(...args);
      dialogRef.current?.close();
      setEditMode("close");
    };
  }, []);

  const withShowModal = useCallback((cb?: any) => {
    return function (...args: any[]) {
      dialogRef.current?.showModal();
      cb?.(args);
    };
  }, []);

  const handleOpenEditNameModal = withShowModal(() => setEditMode("name"));
  const handleOpenEditAddressModal = withShowModal(() =>
    setEditMode("address")
  );
  const handleOpenEditCountryModal = withShowModal(() =>
    setEditMode("country")
  );
  const handleOpenEditEmailModal = withShowModal(() => setEditMode("email"));

  const handleOnSavename = withCloseModal(async (newVal: string) => {
    try {
      await mutateAsync({ name: newVal } as any);
      showSuccess("Name has been updated successfully");
    } catch (error) {
      showError("Name edit failed");
    }
  });
  const handleOnSaveAddress = withCloseModal(async (newVal: string) => {
    try {
      await mutateAsync({ address: newVal } as any);
      showSuccess("Address has been updated successfully");
    } catch (error) {
      showError("Address edit failed");
    }
  });
  const handleOnSaveCountry = withCloseModal(async (newVal: string) => {
    try {
      await mutateAsync({ country: newVal } as any);

      showSuccess("Country has been updated successfully");
    } catch (error) {
      showError("Country edit failed");
    }
  });
  const handleOnSaveEmail = withCloseModal(async (newVal: string) => {
    try {
      await mutateAsync({ email: newVal } as any);

      showSuccess("Email has been updated successfully");
    } catch (error: any) {
      showError("Email edit failed");
    }
  });

  const handleDeactivateChoir = () => {
    alert("This action is not reversible. Proceed to Deactivate your account?");
  };

  const handleChangeCoverImage = () => {
    inputRef.current?.click();
  };

  const handleOnSelectCoverImage = (e: any) => {
    // upload to server and get string and set that as cover.
    const form = new FormData();
    form.set("coverImage", inputRef.current?.files?.[0] as any);
    editCover(form as any);
  };

  useEffect(() => {
    fetchChoirProfile();
  }, []);

  useEffect(() => {
    if (data) {
      dispatch(authActions.setUser(data));
    }
  }, [data]);

  return (
    <section className="w-full px-6 pl-8 pt-5">
      <div className="h-settingsHeader w-full pt-5 flex">
        <div className="relative h-[90%] w-[300px] ">
          <img
            src={data?.coverImage}
            className=" h-full w-full object-cover rounded-[5px] overflow-hidden"
          />
          {RenderIf(
            isUpdatingCover,
            <div className="absolute flex top-0 h-full w-full bg-[#00000050]">
              <Typography variant="pMid" className="m-auto w-fit text-white">
                loading...
              </Typography>
            </div>
          )}
        </div>
        <div className="flex flex-col w-full h-[90%] ml-10">
          <div className="flex gap-x-5 flex items-center mt-auto">
            <Typography variant="h3">{data?.name}</Typography>
            <Button
              onClick={handleOpenEditNameModal}
              variant="nav"
              className="mb-2"
            >
              <Icon
                icon="material-symbols:edit-outline"
                className="text-[20px]"
              />
            </Button>
          </div>
          <div className="flex mt-auto w-full items-center justify-between">
            <Button onClick={handleChangeCoverImage} variant="nav">
              Edit Choir Cover
              <input
                type="file"
                onChange={handleOnSelectCoverImage}
                ref={inputRef}
                className="hidden"
              />
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-16">
        <div className="flex items-center gap-x-2 mb-2">
          <Icon icon="iconamoon:profile-light" className="text-[25px] mb-1" />
          <Typography variant="h6">Profile Data</Typography>
        </div>
        <div>
          <Typography variant="small" isLabel>
            Email
          </Typography>

          <div className="flex w-full gap-x-10 items-center">
            <FormInput disabled className="!w-[500px]" value={data?.email} />
            <Button onClick={handleOpenEditEmailModal} variant="nav">
              <Icon
                icon="material-symbols:edit-outline"
                className="text-[20px]"
              />
            </Button>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <div className="flex items-center gap-x-2 mb-2">
          <Icon
            icon="material-symbols:my-location-outline"
            className="text-[20px] mb-1"
          />
          <Typography variant="h6">Location Data</Typography>
        </div>
        <div>
          <Typography variant="small" isLabel>
            Country
          </Typography>

          <div className="flex w-full gap-x-10 items-center">
            <FormInput
              disabled
              className="!w-[500px]"
              value={data?.location?.country}
            />
            <Button onClick={handleOpenEditCountryModal} variant="nav">
              <Icon
                icon="material-symbols:edit-outline"
                className="text-[20px]"
              />
            </Button>
          </div>
        </div>

        <div className="mt-4">
          <Typography variant="small" isLabel>
            Address
          </Typography>

          <div className="flex w-full gap-x-10 items-center">
            <FormInput
              disabled
              className="!w-[500px]"
              value={data?.location?.address}
            />
            <Button onClick={handleOpenEditAddressModal} variant="nav">
              <Icon
                icon="material-symbols:edit-outline"
                className="text-[20px]"
              />
            </Button>
          </div>
        </div>
      </div>
      {/* <div className="mt-10">
        <div>
          <div className="flex gap-x-2 items-center mb-2">
            <Icon
              icon="solar:lock-password-broken"
              className="text-[20px] mb-1"
            />
            <Typography variant="h6">Password and Security</Typography>
          </div>
          <div>
            <Typography variant="small" isLabel>
              Password
            </Typography>

            <div className="flex w-full gap-x-10 items-center">
              <FormInput
                disabled
                type="password"
                className="!w-[500px]"
                value="hc@gmail.com"
              />
              <Button onClick={handleOpenEditPasswordModal} variant="nav">
                <Icon
                  icon="material-symbols:edit-outline"
                  className="text-[20px]"
                />
              </Button>
            </div>
          </div>
        </div>
      </div> */}
      <div className="mt-10">
        <div className="flex gap-x-2 items-center mb-2">
          <Icon
            icon="solar:danger-triangle-linear"
            className="text-danger text-[20px] mb-1"
          />
          <Typography variant="h6" className="text-danger">
            Danger Zone
          </Typography>
        </div>

        <Button
          disabled
          onClick={handleDeactivateChoir}
          variant="auth"
          isDanger
          className="!w-[250px]"
        >
          Deactivate Choir
        </Button>
      </div>
      <dialog aria-modal ref={dialogRef} onClose={withCloseModal()}>
        {RenderIf(
          editMode === "email",
          <EditSingleInputModal
            header="New Email"
            value={data?.email}
            onCancel={withCloseModal()}
            onSave={handleOnSaveEmail}
            buttonProps={{ isLoading: editMode === "email" && isUpdatingMeta }}
          />
        )}
        {RenderIf(
          editMode === "name",
          <EditSingleInputModal
            header="New Name"
            value={data?.name}
            key={data?.name}
            onCancel={withCloseModal()}
            onSave={handleOnSavename}
            buttonProps={{ isLoading: editMode === "name" && isUpdatingMeta }}
          />
        )}
        {RenderIf(
          editMode === "country",
          <EditSingleInputModal
            header="New Country"
            value={data?.location?.country}
            onCancel={withCloseModal()}
            onSave={handleOnSaveCountry}
            buttonProps={{
              isLoading: editMode === "country" && isUpdatingMeta,
            }}
          />
        )}
        {RenderIf(
          editMode === "address",
          <EditSingleInputModal
            header="New Address"
            onCancel={withCloseModal()}
            onSave={handleOnSaveAddress}
            value={data?.location.address}
            buttonProps={{
              isLoading: editMode === "address" && isUpdatingMeta,
            }}
          />
        )}
        {/* {RenderIf(
          editMode === "password",
          <EditPasswordModal
            onSave={() => {
              //
            }}
            onCancel={withCloseModal()}
          />
        )} */}
      </dialog>
    </section>
  );
};

export default Settings;
