import React, { useState } from "react";
import {
  Button,
  ButtonProps,
  FormInput,
  Typography,
} from "@chords/design-system";

interface EditSingleInputModalProps {
  header: string;
  onSave?: (newValue: string) => void;
  onCancel?: () => void;
  value: string;
  inputProps?: Omit<
    React.HTMLAttributes<HTMLInputElement>,
    "onChange" | "value"
  >;
  buttonProps?: ButtonProps;
}

export const EditSingleInputModal = ({
  header,
  value: defaultValue,
  inputProps,
  onCancel,
  onSave,
  buttonProps,
}: EditSingleInputModalProps) => {
  const [value, setValue] = useState(defaultValue);

  return (
    <div className="w-modal">
      <Typography variant="small" isLabel>
        {header}
      </Typography>
      <FormInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        {...inputProps}
      />
      <div className="flex gap-x-5 mt-10">
        <Button variant="cancel" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="auth"
          onClick={() => {
            onSave?.(value);
          }}
          {...buttonProps}
        >
          Save
        </Button>
      </div>
    </div>
  );
};
