import styled from "styled-components";
import {SongStatus} from "admin"
import { Typography, TypographyProps } from "..";
import React from "react";

const Small = ({ children, ...props }: Omit<TypographyProps, "variant">) =>
  React.createElement(Typography, { variant: "tag", ...props }, children);

interface TextProps {
  status: SongStatus;
}

export const Text = styled(Small)<TextProps>`
  color: ${({ status }: any) =>
    status === "approved"
      ? "#5ca52d"
      : status === "rejected"
      ? "#ed2b2a"
      : "#0091b0"};
  padding: 0;
  margin: 0;
`;
