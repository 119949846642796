import { FieldPath, onSnapshot, query, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Repositories } from "../../../infra/repositories";
import { useAuth } from "../../_shared/hooks/useAuth";

export const useAnalytics = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({
    streams: 0,
    rejected: 0,
    approved: 0,
    pending: 0,
    songs: 0,
  });
  const [error, setError] = useState(false);
  const { id } = useAuth().user!;

  useEffect(() => {
    setLoading(true);
    setError(false);
    const qRef = query(
      Repositories.song,
      where(new FieldPath("creatorSummary", "id"), "==", id)
    );
    const unSub = onSnapshot(
      qRef,
      (observer) => {
        const res = observer.docs.reduce(
          (acc: any, curr) => {
            const data = curr.data();
            if (data.status === "pending") acc.pending += 1;
            else if (data.status === "rejected") acc.rejected += 1;
            else if (data.status === "approved") {
              acc.approved += 1;
              acc.songs += 1;
              acc.streams += data.streams;
            }
            return acc;
          },
          {
            streams: 0,
            rejected: 0,
            approved: 0,
            pending: 0,
            songs: 0,
          }
        );
        setData(res);
      },
      (err) => {
        setLoading(false);
        setError(true);
      }
    );

    return function () {
      unSub?.();
    };
  }, [id]);

  return {
    loading,
    error,
    data,
  };
};
