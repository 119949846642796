import React, { useEffect, useRef, useState } from "react";
import {
  UploadAlbumPayload,
  defaultAlbum,
} from "../../../../../redux/slices/createAlbum";
import {
  PhaseContainer,
  PhaseContainerProps,
} from "../singleSongUpload/PhaseContainer";
import { Button, Typography } from "@chords/design-system";
import Input from "../../Input";
import { convertImageFileToBase64Str } from "@chords/web-infrastructure";
import { RenderCondition } from "@chords/design-system/render";
import { Icon } from "@iconify/react";
import { Themes } from "../../../../../application/song";
import {
  SupportedImageFormatMap,
  SupportedImageFormatType,
} from "../../../../../infra/file/supportTypes";

interface AlbumMetaProps extends PhaseContainerProps {
  meta: Partial<UploadAlbumPayload>;
}

export const AlbumMetaModal = ({
  meta,
  onClickNext,
  ...containerProps
}: AlbumMetaProps) => {
  const [errors, setErrors] = useState<any>({});
  const inputRef = useRef<HTMLInputElement>(null);

  const [payload, setPayload] =
    useState<Partial<UploadAlbumPayload>>(defaultAlbum);

  const isSelectedTheme = React.useCallback(
    (theme: any) => {
      return payload.theme?.includes(theme);
    },
    [payload.theme]
  );

  const addTheme = (theme: any) =>
    setPayload((p: any) => {
      const local = { ...p, theme: [...p.theme] };
      local.theme.push(theme);
      return local;
    });
  const removeTheme = (theme: any) =>
    setPayload({
      ...payload,
      theme: payload.theme?.filter((thm: any) => thm !== theme),
    });
  const handleOpenSelectCover = () => {
    if (inputRef.current) {
      inputRef.current?.click();
    }
  };

  const handleOnSelectImage = async () => {
    const file = inputRef.current?.files?.[0];
    if (file) {
      const isSupported =
        SupportedImageFormatMap[file.type as SupportedImageFormatType];
      if (!isSupported) {
        alert(
          "This image type is not supported. Reach out to our support for assistance or convert to any of these types: png, jpg, jpeg, webp"
        );
        return;
      }
      const src = await convertImageFileToBase64Str(file);
      setPayload({
        ...payload,
        coverImage: {
          name: file?.name,
          src,
          file,
        },
      });
    }
  };
  const submit = (e: any) => {
    const errs = createErrors(payload);
    const hasErrs = Object.keys(errs).length > 0;
    if (hasErrs) {
      return setErrors(errs);
    }
    e.target = { value: payload };
    onClickNext?.(e);
  };

  useEffect(() => {
    if (meta) {
      setPayload(meta);
    }
  }, [meta]);

  return (
    <PhaseContainer onClickNext={submit} {...containerProps}>
      <div className="grid grid-cols-4 h-[90%] overflow-auto mt-4">
        <div className="col-span-3 h-full">
          <Typography variant="h6" className="mb-2">
            Metadata
          </Typography>
          <div className="grid grid-cols-2 mt-4">
            <div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Title (required)"
                  name={"title"}
                  type={"text"}
                  value={payload.title}
                  placeholder={""}
                  error={errors.title}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setPayload({ ...payload, title: e.target.value });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Description (optional)"
                  name={"description"}
                  type={"text"}
                  value={payload.description}
                  placeholder={"Inspiration or any relevant info"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setPayload({ ...payload, description: e.target.value });
                  }}
                />
              </div>
            </div>
            <div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Keywords (optional: helps with indexing the album)"
                  name={"related keywords"}
                  type={"text"}
                  value={payload.relatedKeywords}
                  placeholder={"Eg: DOPe, etc"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setPayload({ ...payload, relatedKeywords: e.target.value });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Typography isLabel variant="pMid">
                  Theme (recommendded: select at least one)
                </Typography>
                <div className="flex gap-3 flex-wrap">
                  {Themes.map((item) => {
                    const selected = isSelectedTheme(item);
                    return (
                      <Typography
                        key={item}
                        variant="small"
                        className={`p-1 border-[1px] hover:cursor-pointer ${
                          selected
                            ? "border-1 border-chordsDark border-solid"
                            : ""
                        }`}
                        onClick={() => {
                          if (selected) removeTheme(item);
                          else addTheme(item);
                        }}
                      >
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-1 h-full pb-10 flex flex-col">
          <Typography variant="h6" isError={errors.coverImage} className="mb-6">
            Cover image (Required)
          </Typography>
          {RenderCondition(
            !!payload.coverImage,
            <div className="flex gap-x-4 items-center">
              <img
                src={payload?.coverImage?.src}
                className="w-[100px] h-[95px] rounded-[3px]"
              />
              <Typography variant="pMid" className="">
                {payload?.coverImage?.name}
              </Typography>
            </div>,
            <Typography variant="pMid" className="">
              Not Provided
            </Typography>
          )}

          <div className="mt-auto flex flex-col items-center">
            <Icon
              icon="material-symbols:cloud-upload"
              className="text-[2rem] text-dark-grey"
            />
            <Typography variant="pMid" isLabel className="!my-4">
              Click to select file from local drive
            </Typography>
            <input
              onChange={handleOnSelectImage}
              ref={inputRef}
              type="file"
              className="hidden"
              name="new-album-cover"
              id="new-album-cover"
              accept="image/*"
            />
            <Button onClick={handleOpenSelectCover} variant="nav" className="">
              Select File
            </Button>
          </div>
        </div>
      </div>
    </PhaseContainer>
  );
};

function createErrors(payload: any) {
  const errors: any = {};
  if (!payload.title) errors.title = "Required";
  if (!payload.coverImage) errors.coverImage = "Required";
  return errors;
}
