import React, { createContext, useContext, useState } from "react";
import CreateNewSongModal from "./SingleSongUpload";
import { ISong } from "@chords/core";

export interface UploadSongPayload
  extends Omit<
    ISong,
    "soloists" | "relatedKeywords" | "coverImage" | "hymnRefs" | "composedDate"
  > {
  soloists: string;
  relatedKeywords: string;
  coverImage: any;
  audioSrc: any;
  hymnRefs: string;
  composedDate?: string;
}
type SetState = React.Dispatch<
  React.SetStateAction<Partial<UploadSongPayload>>
>;

export const defaultSong: Partial<UploadSongPayload> = {
  title: "",
  composer: "",
  genre: "",
  description: "",
  conductor: "",
  soloists: "",
  producedBy: "",
  composedDate: "",
  relatedKeywords: "",
  theme: [],
  hymnRefs: "",
  coverImage: null,
  audioSrc: null,
};

export const UploadSongContext = createContext<
  [Partial<UploadSongPayload>, SetState]
>([defaultSong, () => null]);

export const useNewSongState = () => {
  return useContext(UploadSongContext);
};

export const UploadSongRoot = () => {
  const [payload, setPayload] = useState(defaultSong);

  return (
    <UploadSongContext.Provider value={[payload, setPayload]}>
      <CreateNewSongModal />
    </UploadSongContext.Provider>
  );
};
