import axios, { AxiosError, AxiosInstance, AxiosStatic } from "axios";
import { Client } from "./interface";

export type AxiosClientType = AxiosInstance;
export class AxiosClient implements Client<AxiosInstance> {
  private app: AxiosInstance | AxiosStatic;

  constructor() {
    this.app = axios;
  }

  create = (
    baseURL?: string,
    getClientToken?: Function,
    clearToken?: Function
  ) => {
    if (baseURL) {
      this.app = axios.create({ baseURL });
    } else this.app = axios;
    this.addAuthRequestInterceptor(getClientToken);
    this.addUnauthorizedResponseinterceptor(getClientToken);
    return this.app;
  };

  private addAuthRequestInterceptor = (getToken?: Function) => {
    this.app.interceptors.request.use((config) => {
      const token = getToken?.();
      config.headers.Authorization = `Bearer ${token}`;
      config.headers["Content-Type"] = "application/json";
      return config;
    });
  };

  private addUnauthorizedResponseinterceptor = (clearToken?: Function) => {
    this.app.interceptors.response.use(undefined, (err) => {
      if (err?.response?.status === 4000) {
        location.pathname = "/login";
        clearToken?.();
      }
      return Promise.reject(err);
    });
  };
}

export function getErrorMessageFromError(e: AxiosError | Error) {
  if (e instanceof AxiosError) {
    return (e.response?.data as any)?.error ?? "";
  }
  return e.message;
}
