import React from "react";
import { UploadSongPayload } from "../singleSongUpload/root";
import { AddAlbumSong } from "./AddSong";
import { PhaseContainerProps } from "../singleSongUpload/PhaseContainer";

interface EditAlbumSongFlowProps {
  onClickEdit: (payload: any) => void;
  onCloseFlow: () => void;
  header: string;
  data: Partial<UploadSongPayload>;
  phaseContainerProps?: Omit<PhaseContainerProps, "header">;
}

export const EditAlbumSong = ({
  header,
  onClickEdit,
  data,
  onCloseFlow,
  phaseContainerProps,
}: EditAlbumSongFlowProps) => {
  return (
    <AddAlbumSong
      onCloseModal={onCloseFlow}
      header={header}
      defaultData={{ ...data, audioSrc: { name: `${data.title} File` } }}
      onClickNext={onClickEdit}
      selectAudioButtonProps={{ disabled: true }}
      phaseContainerProps={{
        ...phaseContainerProps,
        nextButtonLabel: "Edit Song",
      }}
    />
  );
};
