import React from "react";
import { useMutation } from "react-query";
import { concertService } from "../../application/concert/concert.service";
import { ConcertModal } from "./ModalView";
import { prepareConcertParams } from "./prepareConcertParams";
import { useAuth } from "../_shared/hooks/useAuth";
import { toast } from "react-toastify";
import { ICreatorSummaryForResource } from "@chords/core";

interface CreateConcertModalProps {
  onClose?: () => void;
  onSave?: () => void;
}

export const CreateConcertModal = ({
  onClose,
  onSave,
}: CreateConcertModalProps) => {
  const user = useAuth().user!;
  const { mutateAsync: createConcert, isLoading } = useMutation({
    mutationFn: (params) => concertService.createConcert(user!.id, params),
    onSuccess: onSave,
  });

  const handleCreateConcert = async (meta: any) => {
    const summary: ICreatorSummaryForResource = {
      coverImage: user?.coverImage,
      id: user.id,
      name: user?.name,
      type: user?.type,
    };
    const serializedSummary = JSON.stringify(summary);
    const params = prepareConcertParams.parse({
      ...meta,
      creatorSummary: serializedSummary,
    });
    await createConcert(params as any)
      .then(() => {
        toast("Successfully created concert", {
          type: "success",
          hideProgressBar: true,
        });
      })
      .catch(() =>
        toast("Failed to create concert. Try Again", {
          type: "error",
          hideProgressBar: true,
        })
      );
  };

  return (
    <ConcertModal
      header="New Concert"
      onSave={handleCreateConcert}
      containerProps={{
        nextButtonLabel: "Create Concert",
        nextbuttonProps: {
          isLoading,
        },
        cancelbuttonProps: {
          className: "text-[#00000090]",
          disabled: isLoading,
        },
        onClickCancel: onClose,
        style: {
          height: "550px",
        },
      }}
      selectButtonProps={{
        disabled: isLoading,
      }}
    />
  );
};
