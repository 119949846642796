import React, { ReactNode } from "react";

interface Props {
  condition: boolean;
  children?: ReactNode | ReactNode[];
}

export const Condition = ({ condition, children }: Props) => {
  if (!condition) return null;
  return <>{children}</>;
};
