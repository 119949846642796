import {
  getFirestore,
  collection,
  connectFirestoreEmulator,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { setupCloudDb } from "../fb/setup";
import { RepositoryRefs } from "./types";
import { dotenv } from "../envs";

setupCloudDb();

const db = getFirestore();
const auth = getAuth();
const isDev = dotenv.appEnv !== "production";
if (isDev) {
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
}
const song = collection(db, RepositoryRefs.song);
const album = collection(db, RepositoryRefs.album);
const albumSong = collection(db, RepositoryRefs.albumSong);
const concert = collection(db, RepositoryRefs.concert);
const creatorNotification = collection(db, RepositoryRefs.creatorNotification);

export const Cloud = Object.freeze({
  db,
  auth,
});

export const Repositories = Object.freeze({
  db,
  song,
  album,
  albumSong,
  concert,
  creatorNotification,
});
