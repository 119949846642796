import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../hooks/useRedux";
import {
  Button,
  HeaderImageContainer,
  Typography,
} from "@chords/design-system";
import { useNavigate } from "react-router-dom";
import { toggleCreateSongModal } from "../../../redux/slices/createSongSlice";
import { toggleCreateAlbumModal } from "../../../redux/slices/createAlbum";
import { useAuth } from "../hooks/useAuth";

const Header: React.FunctionComponent = () => {
  const [toggleUploadDialog, setToggleUploadDialog] = useState(false);
  const { user } = useAuth();
  const dialogRef = useRef<HTMLDialogElement>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const toggleDialog = () => setToggleUploadDialog((p) => !p);

  const handleCloseDialog = () => {
    setToggleUploadDialog(false);
  };

  const handleOnClickNotifications = () => {
    //
    navigate("/notifications");
  };

  const handleOpenUploadSongModal = () => {
    dispatch(toggleCreateSongModal());
    handleCloseDialog();
  };

  const handleOpenUploadAlbumModal = () => {
    dispatch(toggleCreateAlbumModal());
    handleCloseDialog();
  };

  useEffect(() => {
    const handleEsc = (e: any) => {
      if (e.key === "Escape") {
        setToggleUploadDialog(false);
      }
    };
    document.addEventListener("keydown", handleEsc);

    return function () {
      //
      document.removeEventListener("keydown", handleEsc);
    };
  }, [toggleUploadDialog]);

  return (
    <div className="z-[1] bg-gradient-to-r from-chordsDark via-chordsMid to-chordsLight flex fixed top-0 w-full h-[70px] shadow-header-shadow justify-between items-center text-white px-8 py-4">
      <div className="flex flex-shrink-0 gap-4 items-center">
        <Typography variant="h5">2C</Typography>
        <div className="w-[1px] bg-white h-4" />
        <Typography variant="pMid">{user?.name}</Typography>
      </div>
      <div className="flex gap-6 items-center flex-shrink-0">
        <div className=" relative w-full  max-w-[15.25rem]   ">
          <Button
            onClick={toggleDialog}
            style={{ color: "#fff", borderColor: "#fff", width: "100px" }}
            variant="nav"
          >
            Upload
          </Button>
          <dialog
            ref={dialogRef}
            open={toggleUploadDialog}
            className="w-48 mr-[-20%] mt-2 shadow-md p-0"
          >
            <Button
              onClick={handleOpenUploadSongModal}
              className="!bg-white !w-full !text-black"
            >
              <Typography variant="pMid">Single song</Typography>
            </Button>

            <Button
              onClick={handleOpenUploadAlbumModal}
              className="!bg-white !w-full !text-black"
            >
              <Typography variant="pMid">Album</Typography>
            </Button>
          </dialog>
        </div>

        <Icon
          icon="clarity:notification-line"
          className="text-[40px] cursor-pointer"
          onClick={handleOnClickNotifications}
        />
        <HeaderImageContainer src={user?.coverImage as string} />
      </div>
    </div>
  );
};

export default Header;
