import React from "react";
import { AddAlbumSong } from "../_shared/components/modal/addAlbumSong/AddSong";

interface UpdateSingleSongProps {
  defaultData: any;
  header: string;
  onClickEdit: (payload: any) => void;
  onClickCancel: () => void;
  loading?: boolean;
}

export const UpdateSingle = ({
  defaultData,
  header,
  onClickCancel,
  onClickEdit,
  loading,
}: UpdateSingleSongProps) => {
  return (
    <AddAlbumSong
      onCloseModal={onClickCancel}
      header={header}
      defaultData={{
        ...defaultData,
        audioSrc: { name: `${defaultData.title} File` },
      }}
      onClickNext={onClickEdit}
      selectAudioButtonProps={{ disabled: true }}
      phaseContainerProps={{
        nextButtonLabel: "Edit Song",
        nextbuttonProps: { isLoading: loading },
      }}
    />
  );
};
