import React, { useEffect, useState } from "react";
import { Button, Loader, Typography } from "@chords/design-system";
import Input from "../../_shared/components/Input";
import { useNavigationWithReload } from "../../_shared/hooks/useAppNavigation";
import { useMutation } from "react-query";
import { choirService } from "../../../application/choir/choir.service";
import { useToastManager } from "../../_shared/hooks/useToastManager";
import { useLocation } from "react-router-dom";
import { useLogin } from "../../_shared/hooks/useLogin";
import { Condition } from "../../../lib/render/condition";
import { toast } from "react-toastify";

const defaultPayload = { email: "", password: "" };

export const Signin = () => {
  const [payload, setPayload] = useState({ ...defaultPayload });
  const nav = useNavigationWithReload();
  const { state } = useLocation();
  const { fn: login, isLoading: isLoggingIn } = useLogin();
  const { showError } = useToastManager();
  const [errs, setErrs] = useState<any>({});

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: (payload: any) => Promise.resolve(choirService.signin(payload)),
  });

  const handleEmailChange = (e: any) => {
    setPayload((p) => ({ ...p, email: e.target.value }));
  };
  const handlePasswordChange = (e: any) => {
    setPayload((p) => ({ ...p, password: e.target.value }));
  };

  const handleSignin = async () => {
    //
    try {
      const err = createPayloadErrors(payload);
      const hasErrs = Object.keys(err).length > 0;
      if (hasErrs) {
        console.log(err);
        setErrs(err);
        toast("Complete Form", { type: "info", hideProgressBar: true });
        return;
      }
      const data = await mutateAsync(payload);

      if (data) {
        await login(data);
      }
    } catch (error: any) {
      showError("Signin Failed.");
    }
  };

  const toReset = () => {
    nav("/password-reset");
  };

  const toSignup = () => {
    nav("/signup");
  };

  useEffect(() => {
    if (state?.token) {
      login(state.token);
    }
  }, [location]);

  return (
    <div className="w-screen h-screen">
      <header className="flex h-[80px] bg-black">
        <div className="container flex items-center">
          <Button
            onClick={toSignup}
            variant="auth"
            className="!border-[1px] !border-[#fff] !border-solid ml-auto"
          >
            Sign up
          </Button>
        </div>
      </header>
      <main className="container flex flex-col mx-auto">
        <div className="mt-20 grid grid-cols-3 mx-auto w-full gap-x-5">
          <section className="col-span-2 flex flex-col">
            <Typography variant="h1">
              Manage your Choral music like a pro
            </Typography>
            <Typography variant="h4">Chords 2C is here!</Typography>
          </section>
          <div className="col-span-1 flex justify-center">
            <div className="flex flex-col px-4 py-4 pb-5 w-[350px] h-[350px] bg-[#f1f1f1]">
              <Input
                className="!w-full"
                containerProps={{ className: "!my-2" }}
                label="Email"
                onChange={handleEmailChange}
                value={payload.email}
                error={errs.email}
              />
              <Input
                className="!w-full"
                containerProps={{ className: "!my-1" }}
                label="Password"
                type="password"
                onChange={handlePasswordChange}
                value={payload.password}
                error={errs.password}
              />
              <div className="flex-col flex mt-auto">
                <Button
                  isLoading={isLoading}
                  onClick={handleSignin}
                  variant="auth"
                  className="!w-full"
                >
                  Sign In
                </Button>
                <Button
                  onClick={toReset}
                  variant="link"
                  className="mt-5 !w-[fit-content] mx-auto"
                >
                  Forgot password? Reset here
                </Button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Condition condition={isLoggingIn}>
        <div className="absolute top-0 w-screen h-screen flex justify-center items-center bg-[#12121260]">
          <Loader />
        </div>
      </Condition>
    </div>
  );
};

function createPayloadErrors(payload: typeof defaultPayload) {
  const errors: any = {};
  if (!payload.email) errors.email = "Required";
  if (!payload.password) errors.password = "Required";
  return errors;
}
