import { initializeApp } from "firebase/app";
const config = {
  apiKey: "AIzaSyC6HX8RZE25to6DWmUGBZU8BePDtMYMv0w",
  authDomain: "chords-dev-da9da.firebaseapp.com",
  projectId: "chords-dev-da9da",
  storageBucket: "chords-dev-da9da.appspot.com",
  messagingSenderId: "746425663672",
  appId: "1:746425663672:web:f7e530b95a1196a144b44d",
  measurementId: "G-SFYSRTMQJ6",
};
export const setupCloudDb = () => {
  return initializeApp(config);
};
