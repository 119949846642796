import { Icon } from "@iconify/react";
import React, { useEffect, useRef, useState } from "react";
import { PhaseContainer } from "./PhaseContainer";
import { Button, Typography } from "@chords/design-system";
import { convertImageFileToBase64Str } from "@chords/web-infrastructure";
import { RenderCondition } from "@chords/design-system/render";
import { syncCreateSongData } from "../../../../../redux/slices/createSongSlice";
import { useCreateSongState } from "../../../hooks/useCreateSongState";
import { useDispatch } from "react-redux";
import {
  SupportedImageFormatMap,
  SupportedImageFormatType,
} from "../../../../../infra/file/supportTypes";
import { toast } from "react-toastify";

interface IPhase2Props {
  prevStep: () => void;
  nextStep: () => void;
  onCancel?: () => void;
}

const Phase2: React.FunctionComponent<IPhase2Props> = ({
  prevStep,
  nextStep,
}) => {
  const { data } = useCreateSongState();
  const dispatch = useDispatch();
  const [audioFile, setAudioFile] = useState<any>(null);
  const [imageFile, setImageFile] = useState<any>(null);

  const audioRef = useRef<HTMLInputElement>(null);
  const imageRef = useRef<HTMLInputElement>(null);

  const handleOpenAudioSrc = () => {
    audioRef.current?.click();
  };

  const handleOpenImageSrc = () => {
    if (imageRef?.current) {
      imageRef.current?.click();
    }
  };

  const handleOnSelectAudio = () => {
    const file = audioRef.current?.files?.[0];
    if (file) {
      const isAudio = file.type.startsWith("audio");
      if (isAudio) {
        setAudioFile({ file, name: file?.name });
      } else {
        toast("File must be an audio file.", {
          type: "info",
          hideProgressBar: true,
        });
      }
    }
  };

  const handleOnSelectImage = async () => {
    const file = imageRef.current?.files?.[0];
    if (file) {
      const isSupported =
        SupportedImageFormatMap[file.type as SupportedImageFormatType];
      if (!isSupported) {
        alert(
          "This image type is not supported. Reach out to our support for assistance or convert to any of these types: png, jpg, jped, webp"
        );
        return;
      }
      const src = await convertImageFileToBase64Str(
        imageRef.current?.files?.[0] as any
      );
      setImageFile({
        file,
        src,
        name: file?.name,
      });
    }
  };

  const handleNext = () => {
    if (!audioFile) {
      return toast("Form is incomplete. Select audio", {
        hideProgressBar: true,
        type: "info",
      });
    }
    if (!imageFile) {
      return toast("Form is incomplete. Select image file", {
        hideProgressBar: true,
        type: "info",
      });
    }

    dispatch(
      syncCreateSongData({
        ...data,
        audioSrc: audioFile,
        coverImage: imageFile,
      })
    );
    nextStep();
  };

  useEffect(() => {
    setAudioFile(data.audioSrc);
    setImageFile(data.coverImage);
  }, [data]);

  return (
    <PhaseContainer
      header="Select Files"
      cancelButtonLabel="Previous"
      cancelbuttonProps={{ className: "!text-[#00000090]" }}
      onClickCancel={prevStep}
      onClickNext={handleNext}
      footer=""
    >
      <div className="relative flex flex-col h-full ">
        <div className="grid grid-cols-2 gap-x-5 mb-5 h-full items-center">
          <div className="flex flex-col h-full p-5 pt-3">
            <Typography variant="h5">Audio source</Typography>
            <div className="w-full mx-auto">
              {RenderCondition(
                !!audioFile,
                <div className="flex items-center gap-x-4 mt-2">
                  <div className="flex w-12 h-12 rounded-full justify-center items-center bg-chordsLight">
                    <Icon
                      icon="material-symbols:play-arrow"
                      className=" text-[32px]"
                    />
                  </div>
                  <Typography variant="pMid" className="">
                    {audioFile?.name}
                  </Typography>
                </div>,
                <Typography variant="pMid">None selected</Typography>
              )}
            </div>
            <div className="mt-auto mx-auto flex flex-col items-center">
              <Icon
                icon="material-symbols:cloud-upload"
                className="text-[2rem] text-dark-grey"
              />
              <Typography variant="pMid" isLabel className="!my-4">
                Click to select file from local drive
              </Typography>
              <input
                onChange={handleOnSelectAudio}
                ref={audioRef}
                type="file"
                className="hidden"
                name="newsong_audio"
                id="newsong_audio"
                accept="audio/*"
              />
              <Button onClick={handleOpenAudioSrc} variant="nav" className="">
                Select File
              </Button>
            </div>
          </div>
          <div className="flex flex-col h-full p-5 pt-3">
            <Typography variant="h5">Cover Image source</Typography>
            <div className="w-full mx-auto">
              {RenderCondition(
                imageFile,
                <div className="flex gap-x-4 items-center">
                  <img
                    src={imageFile?.src}
                    className="w-[100px] h-[95px] rounded-[3px]"
                  />
                  <Typography variant="pMid" className="">
                    {imageFile?.name}
                  </Typography>
                </div>,
                <Typography variant="pMid">None selected</Typography>
              )}
            </div>
            <div className="mt-auto mx-auto flex flex-col items-center">
              <Icon
                icon="material-symbols:cloud-upload"
                className="text-[2rem] text-dark-grey"
              />
              <Typography variant="pMid" isLabel className="!my-4">
                Click to select file from local drive
              </Typography>
              <Button onClick={handleOpenImageSrc} variant="nav" className="">
                Select File
              </Button>
              <input
                onChange={handleOnSelectImage}
                type="file"
                ref={imageRef}
                className="hidden"
                name="newsong_cover"
                id="newsong_cover"
                accept="image/*"
              />
            </div>
          </div>
        </div>
        <div className="mt-auto mb-10 text-center">
          <Typography variant="pMid">
            On click Next, you agree that your song be published and managed on
            all Chords platforms.
          </Typography>
        </div>
      </div>
    </PhaseContainer>
  );
};

export default Phase2;

function createErrors(payload: any) {
  const errors: any = {};
  return errors;
}
