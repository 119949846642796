export async function convertImageFileToBase64Str(file: File) {
  // const blob = file.arrayBuffer
  const buffer = await file.arrayBuffer();
  const bufferToString = parseBufferToString(buffer);
  return `data:image/jpg;base64, ${bufferToString}`;
}

function parseBufferToString(buffer: any) {
  let binary = "";
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
