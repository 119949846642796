import {
  onSnapshot,
  query,
  where,
  orderBy,
  FieldPath,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { Repositories } from "../../../infra/repositories";
import { useAuth } from "../../_shared/hooks/useAuth";

/**
 * TODO
 * Due date order not working
 */

export const useUpcomingConcerts = () => {
  const [loading, setLoading] = useState(false);
  const [upcoming, setUpcoming] = useState<any[]>([]);
  const [past, setPast] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const { id } = useAuth().user!;

  useEffect(() => {
    setLoading(true);
    setError(false);
    const qRef = query(
      Repositories.concert,
      where(new FieldPath("creatorSummary", "id"), "==", id),
      orderBy("dueDate", "desc")
    );
    const unSub = onSnapshot(
      qRef,
      (observer) => {
        if (observer.docs.length > 0) {
          const _past: any[] = [];
          const _upc: any[] = [];
          observer.docs.forEach((doc) => {
            const data = { ...doc.data(), id: doc.id } as any;
            const isUpcoming =
              new Date(data.dueDate).getTime() > new Date().getTime();
            if (isUpcoming) {
              _upc.push(data);
            } else {
              _past.push(data);
            }
          });
          setPast(_past);
          setUpcoming(_upc);
        }
        setLoading(false);
      },
      (err) => {
        console.log("-------UpNext concert -------");
        console.log(err);
        console.log("-------");
        setLoading(false);
        setError(true);
      }
    );

    return function () {
      unSub?.();
    };
  }, [id]);

  return {
    loading,
    error,
    past,
    upcoming,
  };
};
