import React, { useState } from "react";
import Input from "../../Input";
import { PhaseContainer } from "../singleSongUpload/PhaseContainer";
import { Button, Typography } from "@chords/design-system";
import { Icon } from "@iconify/react";
import { useCreateAlbumState } from "../../../hooks/useCreateSongState";
import {
  UploadAlbumSongPayload,
  addSong,
  defaultAlbumSong,
  removeSong,
} from "../../../../../redux/slices/createAlbum";
import { RenderCondition } from "@chords/design-system/render";
import { useDispatch } from "react-redux";
import { Genre, Themes } from "../../../../../application/song";
import { toast } from "react-toastify";

interface UploadPayload extends Partial<UploadAlbumSongPayload> {
  edit?: boolean;
}

interface IPhase2Props {
  prevStep: () => void;
  nextStep: () => void;
  AddedSongsNode?: React.ReactNode;
  step: number;
}

const Phase2: React.FunctionComponent<IPhase2Props> = ({
  prevStep,
  nextStep,
}) => {
  const dispatch = useDispatch();

  const [songPayload, setSongPayload] = React.useState<UploadPayload>({
    ...defaultAlbumSong,
    edit: false,
  });
  const { songs } = useCreateAlbumState();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const submittedSongs = React.useMemo(() => {
    return Object.values(songs);
  }, [songs]);
  const [errors, setErrors] = useState<any>({});

  const isEditing = React.useMemo(() => {
    return Boolean(songPayload?.edit);
  }, [songPayload]);

  const isSelectedTheme = React.useCallback(
    (theme: any) => {
      return songPayload.theme?.includes(theme);
    },
    [songPayload.theme]
  );

  const isSelectedGenre = React.useCallback(
    (genre: any) => {
      return songPayload.genre === genre;
    },
    [songPayload.genre]
  );

  const addTheme = (theme: any) =>
    setSongPayload((p: any) => {
      const local = { ...p, theme: [...p.theme] };
      local.theme.push(theme);
      return local;
    });
  const removeTheme = (theme: any) =>
    setSongPayload({
      ...songPayload,
      theme: songPayload.theme?.filter((thm: any) => thm !== theme),
    });

  const handleOnUpdateGenre = (item: any) => {
    setSongPayload((p) => {
      const local = { ...p };
      local.genre = item;
      return local;
    });
  };

  const handleAddSong = () => {
    const errs = createErrors(songPayload);
    const hasErrs = Object.keys(errs).length > 0;
    if (hasErrs) {
      setErrors(errs);
      return toast("Form is incomplete!", {
        hideProgressBar: true,
        type: "info",
      });
    }
    const key = Object.keys(songs).length + 1;
    dispatch(addSong({ ...songPayload, key }));
    setSongPayload({ ...defaultAlbumSong, key });
    setErrors({});
  };

  const handleRemoveSong = (key: any) => {
    dispatch(removeSong(key));
  };

  const handleSelectAudio = () => {
    inputRef.current?.click();
  };

  const handleOnSelectFile = () => {
    const file = inputRef.current?.files?.[0];
    if (file) {
      const isAudio = file.type.startsWith("audio");
      if (!isAudio) {
        return toast("File must be an audio file.", {
          type: "info",
          hideProgressBar: true,
        });
      }
      setSongPayload((p) => ({
        ...p,
        audioSrc: {
          file,
          name: file.name,
        },
      }));
    }
  };

  const handleEditSong = () => {
    const local = { ...songPayload };
    delete local?.edit;
    const errs = createErrors(songPayload);
    const hasErrs = Object.keys(errs).length > 0;
    if (hasErrs) {
      setErrors(errs);
      return toast("Form is incomplete!", {
        hideProgressBar: true,
        type: "info",
      });
    }
    dispatch(addSong(local));
    setSongPayload({ ...defaultAlbumSong, edit: false });
    setErrors({});
  };

  const handleViewSong = (song: any) => {
    setSongPayload({ ...song, edit: true });
  };

  const submit = () => {
    if (submittedSongs.length < 1) {
      return toast("Add at least one song to album", {
        type: "info",
        hideProgressBar: true,
      });
    }
    nextStep();
  };

  return (
    <PhaseContainer
      header="Add Song"
      style={{ height: "600px" }}
      footerComponent={() => {
        return (
          <div className="w-full flex bg-white pt-4 ">
            {RenderCondition(
              isEditing,
              <Button onClick={handleEditSong} variant="auth">
                Continue
              </Button>,
              <Button onClick={handleAddSong} variant="auth">
                Add Song
              </Button>
            )}
            <div className="w-fit flex gap-x-4 ml-auto">
              <Button
                onClick={prevStep}
                variant="cancel"
                className="!text-[#00000090]"
              >
                Previous
              </Button>
              <Button onClick={submit} variant="auth">
                Next
              </Button>
            </div>
          </div>
        );
      }}
    >
      <div className="w-full h-full overflow-auto grid grid-cols-3">
        <div className="col-span-2">
          <Typography variant="h6" className="mb-2">
            Metadata
          </Typography>
          <div className="grid grid-cols-2">
            <div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Title (required)"
                  name={"title"}
                  error={errors.title}
                  type={"text"}
                  value={songPayload?.title}
                  placeholder={"Type song title"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({ ...songPayload, title: e.target.value });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Composer (required)"
                  name={"composer"}
                  type={"text"}
                  value={songPayload.composer!}
                  placeholder={"Type song title"}
                  error={errors.composer}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      composer: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Conductor (optional)"
                  name={"conductor"}
                  type={"text"}
                  value={songPayload.conductor!}
                  placeholder={"Type song title"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      conductor: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Composed Date(optional)"
                  name={"composedDate"}
                  type={"date"}
                  value={songPayload?.composedDate}
                  placeholder={"Composed date"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      composedDate: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Soloists (required)"
                  name={"soloists"}
                  type={"text"}
                  value={songPayload?.soloists}
                  placeholder={"Enter comma separated names"}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      soloists: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <div className="w-fit flex flex-col gap-4">
                <Typography
                  isLabel={!errors.genre}
                  isError={errors.genre}
                  variant="pMid"
                >
                  Genre (required: select one)
                </Typography>
                <div className="flex gap-3 flex-wrap">
                  {Genre.map((item) => {
                    const selected = isSelectedGenre(item);
                    return (
                      <Typography
                        key={item}
                        variant="small"
                        className={`p-1 border-[1px] hover:cursor-pointer ${
                          selected
                            ? "border-1 border-chordsDark border-solid"
                            : ""
                        }`}
                        onClick={() => handleOnUpdateGenre(item)}
                      >
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              </div>
              <div className="w-fit flex flex-col gap-4">
                <Typography isLabel variant="pMid">
                  Theme (optional: recommended)
                </Typography>
                <div className="flex gap-3 flex-wrap">
                  {Themes.map((item) => {
                    const selected = isSelectedTheme(item);
                    return (
                      <Typography
                        key={item}
                        variant="small"
                        className={`p-1 border-[1px] hover:cursor-pointer ${
                          selected
                            ? "border-1 border-chordsDark border-solid"
                            : ""
                        }`}
                        onClick={() => {
                          if (selected) removeTheme(item);
                          else addTheme(item);
                        }}
                      >
                        {item}
                      </Typography>
                    );
                  })}
                </div>
              </div>
              <div className="max-w-[24rem] mt-5 w-full">
                <Input
                  label="References to other Hymns (optional)"
                  name={"hymnRefs"}
                  type={"text"}
                  value={songPayload?.hymnRefs}
                  placeholder={"Eg: CH 123, MHB 332, etc..."}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      hymnRefs: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Related Keywords (optional)"
                  name={"relatedKeywords"}
                  type={"text"}
                  value={songPayload?.relatedKeywords}
                  placeholder={"Eg: Kelencha, Adom,..."}
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      relatedKeywords: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="max-w-[24rem] w-full">
                <Input
                  label="Description (optional)"
                  name={"description"}
                  type={"text"}
                  value={songPayload.description!}
                  placeholder={
                    "An inspiration behind the song or any relevant info"
                  }
                  onChange={function (
                    e: React.ChangeEvent<HTMLInputElement>
                  ): void {
                    setSongPayload({
                      ...songPayload,
                      description: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="h-full">
          <div className="grid grid-rows-2 h-full">
            <div className="row-span-1">
              <Typography
                variant="h6"
                isError={errors.coverImage}
                className="pb-2"
              >
                Audio File (required)
              </Typography>
              <div className="w-full mx-auto">
                {RenderCondition(
                  songPayload.audioSrc,
                  <div className="flex items-center gap-x-4 mt-2">
                    <div className="flex w-10 h-10 flex-shrink-0 rounded-full justify-center items-center bg-chordsLight">
                      <Icon
                        icon="material-symbols:play-arrow"
                        className=" text-[32px]"
                      />
                    </div>
                    <Typography variant="pMid" className="">
                      {songPayload?.audioSrc?.name}
                    </Typography>
                  </div>,
                  <Typography variant="pMid">None selected</Typography>
                )}
              </div>
              <div className="mt-auto mx-auto flex flex-col items-center">
                <Icon
                  icon="material-symbols:cloud-upload"
                  className="text-[2rem] text-dark-grey"
                />
                <Typography variant="pMid" isLabel className="!my-4">
                  Click to select file from local drive
                </Typography>
                <input
                  key={songPayload.key}
                  onChange={handleOnSelectFile}
                  type="file"
                  ref={inputRef}
                  className="hidden"
                  name="music"
                  id="create-album-song-music"
                  accept="audio/*"
                />
                <Button onClick={handleSelectAudio} variant="nav" className="">
                  Select File
                </Button>
              </div>
            </div>
            <div className="row-span-1">
              <Typography variant="h6" className="mb-2">
                Songs
              </Typography>

              <div className="h-[240px] overflow-y-auto">
                {submittedSongs.map((item, key) => {
                  return (
                    <div
                      key={key}
                      onClick={() => handleViewSong(item)}
                      className="flex px-2 py-[6px] hover:cursor-pointer hover:bg-chordsLight w-full"
                    >
                      <Typography variant="pMid">{item.title}</Typography>
                      <Button
                        onClick={() => handleRemoveSong(item.key)}
                        variant="nav"
                        className="ml-auto"
                      >
                        <Icon icon="fluent:delete-20-regular" />
                      </Button>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PhaseContainer>
  );
};

export default Phase2;

function createErrors(payload: any) {
  const errors: any = {};
  if (!payload.title) errors.title = "Required";
  if (!payload.composer) errors.composer = "Required";
  if (!payload.genre) errors.genre = "Required";
  if (!payload.audioSrc) errors.coverImage = "Required";
  return errors;
}
