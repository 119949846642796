import React from "react";
import { Icon, IconProps } from "@iconify/react";
import { IChoirNotificationType } from "@chords/core";

interface NotificationTypeProps extends Omit<IconProps, "icon"> {
  type: IChoirNotificationType;
}

export const NotificationType = ({
  type,
  className,
  ...props
}: NotificationTypeProps) => {
  switch (type) {
    case "account":
      return (
        <Icon
          icon="mdi:account-sync-outline"
          className={`text-[18px] text-white ${className}`}
          {...props}
        />
      );
    case "auth":
      return (
        <Icon
          icon="mdi:shield-account"
          className={`text-[18px] text-white ${className}`}
          {...props}
        />
      );

    case "upload":
      return (
        <Icon
          icon="solar:cloud-upload-bold"
          className={`text-[24px] text-white ${className}`}
          {...props}
        />
      );
    case "info":
      return (
        <Icon
          icon="material-symbols:info-i-rounded"
          className={`text-[18px] text-white ${className}`}
          {...props}
        />
      );
    default:
      return (
        <Icon
          icon="material-symbols:manage-accounts-rounded"
          className={`text-[18px] text-white ${className}`}
          {...props}
        />
      );
  }
};
