import { createSlice, PayloadAction as PA } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import { GenreType, GlobalAuth, ThemeType } from "./interface";

const defaultAuth: GlobalAuth = {
  isLoggedIn: false,
  themes: [],
  genre: [],
  user: null,
};

const persistConfig = { key: "chords-2c-auth", storage, version: 2 };

const authSlice = createSlice({
  name: "auth",
  initialState: defaultAuth,
  reducers: {
    setAuth: (state, { payload }) => {
      state.user = payload;
      state.isLoggedIn = true;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
    },
    setDefaultPayloads: (
      state,
      { payload }: PA<{ themes: ThemeType[]; genre: GenreType[] }>
    ) => {
      state.themes = payload.themes;
      state.genre = payload.genre;
    },
    reseet: (state) => {
      state.user = defaultAuth.user;
      state.genre = [];
      state.themes = [];
      state.isLoggedIn = false;
    },
  },
});

export const { actions: authActions } = authSlice;
export const authReducer = persistReducer(persistConfig, authSlice.reducer);
