import { FormInput, Typography } from "@chords/design-system";
import { RenderIf } from "@chords/design-system/render";
import * as React from "react";

export interface IInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

const Input: React.FunctionComponent<IInputProps> = ({
  label,
  containerProps,
  error,
  ...props
}) => {
  return (
    <div
      className={`flex my-4 gap-3 flex-col w-full ${containerProps?.className}`}
      {...containerProps}
    >
      {label && (
        <Typography variant="pMid" isLabel isError>
          {label}
        </Typography>
      )}

      <FormInput {...props} />
      {RenderIf(
        !!error,
        <Typography variant="small" isError>
          {error}
        </Typography>
      )}
    </div>
  );
};

export default Input;
