import React, { useState } from "react";
import { Button, ButtonProps, Typography } from "@chords/design-system";
import Input, { IInputProps } from "../../_shared/components/Input";
import { RenderIf } from "@chords/design-system/render";

interface VerificationFormProps {
  label: string;
  cancelText?: string;
  cancelButtonProps?: ButtonProps;
  inputProps?: IInputProps;
  proceedButtonProps?: ButtonProps;
}

export const VerifyAccount = ({
  label,
  cancelButtonProps,
  cancelText,
  inputProps,
  proceedButtonProps,
}: VerificationFormProps) => {
  return (
    <div className="mx-auto flex flex-col mt-12 items-center">
      <Typography variant="pMid">{label}</Typography>
      <div className="mt-5 w-[350px] py-5 flex flex-col gap-y-5">
        <Input
          className="!w-full"
          containerProps={{ className: "!my-2" }}
          {...inputProps}
        />
        <Button
          variant="auth"
          className="!w-full mt-auto"
          {...proceedButtonProps}
        >
          Proceed
        </Button>
      </div>
      {RenderIf(
        !!cancelText,
        <Button
          variant="link"
          className="!w-fit mt-auto"
          {...cancelButtonProps}
        >
          {cancelText}
        </Button>
      )}
    </div>
  );
};
