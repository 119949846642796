import { Typography } from "@chords/design-system";
import { Icon } from "@iconify/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { UploadEvents } from "../../../lib/events/events";
import { UploadItem } from "./UploadItem";

export const UploadsQueueComponent = () => {
  const [opened, setOpened] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [uploads, setUploads] = useState<any[]>([]);

  const handleOnUploadEvent = useCallback((e: any) => {
    //
    setUploads((p) => [...p, e.detail.payload]);
  }, []);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener(
        UploadEvents.UPLOAD_ALBUM,
        handleOnUploadEvent
      );
      ref.current.addEventListener(
        UploadEvents.UPLOAD_SONG,
        handleOnUploadEvent
      );
      ref.current.addEventListener(
        UploadEvents.UPLOAD_ALUM_SONG,
        handleOnUploadEvent
      );
    }

    return () => {
      if (ref.current) {
        ref.current.removeEventListener(
          UploadEvents.UPLOAD_ALBUM,
          handleOnUploadEvent
        );
        ref.current.removeEventListener(
          UploadEvents.UPLOAD_SONG,
          handleOnUploadEvent
        );
        ref.current.removeEventListener(
          UploadEvents.UPLOAD_ALUM_SONG,
          handleOnUploadEvent
        );
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      id="upload-stack-manager"
      className={`${
        !opened ? "h-[70px]" : "h-[280px]"
      } w-[350px] bg-chordsDark shadow-3xl fixed bottom-0 right-[20px] rounded-0`}
    >
      <div className=" h-[70px] flex gap-x-5 p-2 py-4 px-3 items-center justify-between">
        <Typography variant="h6" className="text-white">
          Uploads
        </Typography>
        <Icon
          onClick={() => setOpened((p) => !p)}
          icon="mdi:arrow-up-drop-circle"
          className="text-[32px] text-white"
        />
      </div>
      <div className="h-[210px] bg-white overflow-y-auto pb-2 ">
        {uploads.map((item, key) => {
          return <UploadItem data={item} key={key} />;
        })}
      </div>
    </div>
  );
};
