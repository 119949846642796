import * as React from "react";
import { Button, CoverText, Song, Typography } from "@chords/design-system";
import { useMutation } from "react-query";
import { choirService } from "../../application/choir/choir.service";
import { RenderCondition, RenderIf } from "@chords/design-system/render";
import { UpdateSingle } from "./UpdateSingle";
import { UploadSongPayload } from "../_shared/components/modal/singleSongUpload/root";
import { Icon } from "@iconify/react";
import { DeleteAlbumSongModal } from "../_shared/components/modal/DeleteAlbumSongPrompt";
import { songUploadUtilService } from "../../application/song";
import { useToastManager } from "../_shared/hooks/useToastManager";
import { useAuth } from "../_shared/hooks/useAuth";
import { useSingleSongsUploads } from "./hooks/useSingleUploads";
import { useAlbumUploads } from "./hooks/useAlbumUploads";
import { useNavigate } from "react-router-dom";
import { isOutdatedResourceError } from "./errorhandlers/outdatedResource";

const Uploads: React.FunctionComponent = () => {
  const { user } = useAuth();
  const nav = useNavigate();
  const id = user?.id ?? "";
  const { show, update } = useToastManager();
  const deleteModalRef = React.useRef<HTMLDialogElement>(null);
  const [toEdit, setToEdit] = React.useState<UploadSongPayload | null>(null);
  const [toDelete, setToDelete] = React.useState<UploadSongPayload | null>(
    null
  );
  const { data: singleSongsUploads } = useSingleSongsUploads();
  const { data: albumUploads } = useAlbumUploads();

  const { mutateAsync, isLoading: isEditingSong } = useMutation({
    mutationKey: [`update-single-song`, `${(toEdit as any)?.id}`],
    mutationFn: (payload: any) =>
      choirService.updateSingleSong(id, (toEdit as any)?.id, payload),
    onError: () => {
      update(`Failed to update ${toEdit?.title}`, "error");
    },
  });

  const { mutateAsync: deleteSong, isLoading: isDeleting } = useMutation({
    mutationKey: [`delete-single-song`, `${(toDelete as any)?.id}`],
    mutationFn: () => choirService.removeSingleSong(id, (toDelete as any)?.id),
  });

  const handleExploreAlbum = (id: string) => {
    nav(`/album/${id}`);
  };

  const handleOpenEditSong = (song: any) => {
    setToEdit(song);
  };

  const handleEditSong = async (song: any) => {
    //
    if (toEdit) {
      const rawPayload = songUploadUtilService.createMetaDiff(toEdit, song);
      const parsedPayload =
        songUploadUtilService.createEditAlbumPayload(rawPayload);
      const proceed = Object.values(parsedPayload).flatMap((d) => d).length > 0;
      if (proceed) {
        await mutateAsync(parsedPayload);
        show("Successfully Updated song", { type: "success" });
        handleCloseEdit();
      }
    }
  };
  const handleOpenDeleteSong = (song: any) => {
    //
    setToDelete(song);
    deleteModalRef.current?.showModal();
  };

  const handleCloseEdit = () => setToEdit(null);
  const handleCloseDelete = () => {
    deleteModalRef.current?.close();
  };

  const handelDeleteSong = async () => {
    //
    try {
      await deleteSong();
      show("Successfully Deleted song", { type: "success" });
      handleCloseDelete();
    } catch (error: any) {
      if (isOutdatedResourceError(error)) {
        alert(
          "You are unable to delete this resource because Chords managed its earlier release. Reach out to support for assistance to delete"
        );
      } else {
        show("Failed to delete song", { type: "error" });
      }
    }
  };

  return (
    <section className="w-full px-8 pb-8  pt-6">
      <section id="single-uploads">
        <Typography variant="h4">Single Uploads</Typography>
        <div className="2xl:w-[80%] w-full mt-4 h-[500px] box-border p-4 flex flex-col shadow-lg flex-shrink-0 flex-grow ">
          <div className="mt-4 overflow-y-auto flex flex-col gap-8 flex-grow  h-[700px] w-full">
            {RenderCondition(
              singleSongsUploads.length > 0,
              singleSongsUploads.map((song: any) => {
                const showDel = song.type === "single";
                return (
                  <div
                    onClick={() => {
                      if (showDel) handleOpenEditSong(song);
                    }}
                    key={song.id}
                    className="flex justify-between items-center hover:bg-chordsLightBg px-2"
                  >
                    <Song
                      className="!m-0 pt-1 !mt-0 w-[80%] hover:cursor-pointer"
                      data={song}
                    />
                    {RenderIf(
                      showDel,
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleOpenDeleteSong(song);
                        }}
                        variant="nav"
                        className="ml-auto"
                      >
                        <Icon icon="fluent:delete-20-regular" />
                      </Button>
                    )}
                  </div>
                );
              }),
              <div className="h-full w-full flex items-center justify-center">
                <Typography variant="h4" isLabel>
                  No Data
                </Typography>
              </div>
            )}
          </div>
        </div>
      </section>
      <section id="album-uploads" className="mt-20">
        <Typography variant="h4">Album Uploads</Typography>
        <div className="flex flex-wrap gap-x-20 w-full 2xl:w-[80%] ">
          {RenderCondition(
            albumUploads.length > 0,
            albumUploads.map((album: any) => {
              return (
                <CoverText
                  className="hover:cursor-pointer"
                  onClick={() => handleExploreAlbum(album.id)}
                  coverProps={{ className: "!h-[200px] !w-[200px]" }}
                  key={album.id}
                  data={{ cover: album.coverImage, title: album.title }}
                />
              );
            }),
            <div className="h-40 shadow-lg w-full flex items-center justify-center">
              <Typography variant="h4" isLabel>
                No Data
              </Typography>
            </div>
          )}
        </div>
      </section>
      {RenderIf(
        !!toEdit,
        <UpdateSingle
          defaultData={toEdit!}
          header={`Edit ${toEdit?.title}`}
          onClickEdit={handleEditSong}
          onClickCancel={handleCloseEdit}
          loading={isEditingSong}
        />
      )}
      <dialog ref={deleteModalRef}>
        <DeleteAlbumSongModal
          header={`Delete "${toDelete?.title}"?.`}
          onClickCancel={handleCloseDelete}
          onClickDelete={handelDeleteSong}
          deleteButtonProps={{
            isLoading: isDeleting,
            loaderProps: { color: "#fff" },
          }}
        />
      </dialog>
    </section>
  );
};

export default Uploads;
