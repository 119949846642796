import { Typography } from "@chords/design-system";
import React, { useRef, useState } from "react";
import { DisplayNotification } from "./display";
import { RenderCondition, RenderIf } from "@chords/design-system/render";
import { NotificationItem } from "./notificationItem";
import { useNotifications } from "./hooks/useNotifications";

export const NotificationPage = () => {
  const [notification, setNotification] = useState<any>(null);
  const dialogRef = useRef<HTMLDialogElement>(null);
  const { data } = useNotifications();

  const handleDisplayNotification = () => {
    dialogRef.current?.showModal();
  };

  const handleCloseModal = () => {
    dialogRef.current?.close();
  };

  const handleOnClickNotification = (data: any) => {
    setNotification(data);
    handleDisplayNotification();
  };

  return (
    <div className="w-full h-full p-6">
      <Typography variant="h3">Notifications</Typography>
      <div className="mt-6 flex flex-col gap-y-3">
        {RenderCondition(
          data?.length > 0,
          data?.map((notification: any) => {
            return (
              <NotificationItem
                onClick={handleOnClickNotification}
                data={notification}
                key={notification.id}
              />
            );
          }),
          <Typography variant="h4" isLabel>
            No Data
          </Typography>
        )}
      </div>
      <dialog ref={dialogRef}>
        {RenderIf(
          notification,
          <DisplayNotification onClose={handleCloseModal} data={notification} />
        )}
      </dialog>
    </div>
  );
};
