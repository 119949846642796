import { Button, CoverText, Typography } from "@chords/design-system";
import React, { useState } from "react";
import { CreateConcertModal } from "./createConcertModal";
import { RenderCondition, RenderIf } from "@chords/design-system/render";
import { EditConcert } from "./editConcertModal";
import { useUpNextConcert } from "./hooks/useUpNextConcert";
import { useUpcomingConcerts } from "./hooks/useComingConcerts";

export const Concert = () => {
  const [toEdit, setToEdit] = useState(null);
  const [open, setOpen] = useState(false);
  const { data: upNextConcert } = useUpNextConcert();
  const { past, upcoming } = useUpcomingConcerts();

  const handleOpenConcertDialog = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOnSave = () => {
    handleCloseModal();
  };

  const handleCreateNewConcert = () => {
    if (toEdit) setToEdit(null);
    handleOpenConcertDialog();
  };

  const handleEditUpcomingConcert = () => {
    if (upNextConcert) {
      handleOpenConcertDialog();
      setToEdit(upNextConcert);
    }
  };

  const handleEditConcert = (concert: any) => {
    handleOpenConcertDialog();
    setToEdit(concert);
  };

  const handleOncloseEditConcert = () => {
    setToEdit(null);
    handleCloseModal();
  };

  return (
    <main>
      {RenderCondition(
        !!upNextConcert,
        <div className="relative h-concertBanner flex justify-between">
          <img
            src={upNextConcert?.coverImage}
            className="absolute z-[-1] w-full h-full object-cover"
          />
          <div className="flex flex-col text-white py-10 pl-8 bg-gradient-to-r from-[#000] from-10% via-[#00000090] to-[transparent]">
            <Typography variant="h5">Up Next!</Typography>
            <div className="mt-auto h-fit">
              <Typography variant="h3">{upNextConcert?.title}</Typography>
              <div className="flex gap-x-4">
                <div className="flex">
                  <Typography variant="p">{upNextConcert?.location}</Typography>
                </div>
                <div className="flex">
                  <Typography variant="p">
                    {upNextConcert?.dateAndTime}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>,
        <div>
          <Typography variant="h3">No Upcoming concerts</Typography>
        </div>
      )}
      <section className="p-8">
        <div className="flex gap-x-4">
          <Button onClick={handleCreateNewConcert}>Create Concert</Button>
          <Button
            onClick={handleEditUpcomingConcert}
            variant="outlined"
            disabled={!upNextConcert}
          >
            Edit Upcoming concert
          </Button>
        </div>
        <section className="mt-10">
          <Typography variant="h4">Upcoming Concerts</Typography>
          <div className="flex flex-wrap w-full xl:w-[80%] min-h-[200px]">
            {RenderCondition(
              upcoming.length > 0,
              upcoming.map((concert: any) => (
                <CoverText
                  key={concert.id}
                  data={{ cover: concert?.coverImage, title: concert.title }}
                  onClick={() => handleEditConcert(concert)}
                />
              )),
              <Typography variant="h5" isLabel>
                No Data
              </Typography>
            )}
          </div>
        </section>
        <section className="mt-3">
          <Typography variant="h4">Past Concerts</Typography>
          <div className="flex flex-wrap w-full xl:w-[80%] min-h-[200px]">
            {RenderCondition(
              past.length > 0,
              past.map((concert: any) => (
                <CoverText
                  key={concert.id}
                  onClick={() => handleEditConcert(concert)}
                  data={{ cover: concert.coverImage, title: concert.title }}
                />
              )),
              <Typography variant="h5" isLabel>
                No Data
              </Typography>
            )}
          </div>
        </section>
      </section>
      {RenderIf(
        open,
        RenderCondition(
          Boolean(toEdit),
          <EditConcert
            concert={toEdit}
            onClose={handleOncloseEditConcert}
            onSave={handleOnSave}
          />,
          <CreateConcertModal
            onClose={handleCloseModal}
            onSave={handleOnSave}
          />
        )
      )}
      {/* </dialog> */}
    </main>
  );
};
