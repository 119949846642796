import { useCallback } from "react";
import { decodeJWT } from "../../../infra/decodeJWT";
import { authActions } from "../../../redux/slices/auth.slice";
import { useAppDispatch } from "./useRedux";
import { LocalStorage, StorageKeys } from "../../../infra/localStorage";
import { useToastManager } from "./useToastManager";
import { useNavigationWithReload } from "./useAppNavigation";
import { choirService } from "../../../application/choir/choir.service";
import { useMutation } from "react-query";
import { CloudService } from "../../../application/cloud/cloud-service";
import { ILoginPayload } from "../../../application/auth/types";
type LoginFn = (payload: ILoginPayload) => Promise<void>;

export const useLogin = () => {
  const dispatch = useAppDispatch();
  const { showSuccess } = useToastManager();
  const nav = useNavigationWithReload();
  const fn = useCallback(async (payload: ILoginPayload) => {
    const { cloudAuthToken, token } = payload;
    await CloudService.signInWithToken(cloudAuthToken);
    const { id } = decodeJWT(token);
    const user = await choirService.getChoirProfile(id);
    dispatch(authActions.setAuth(user));
    LocalStorage.set(StorageKeys.ACCESS_TOKEN, token);
    LocalStorage.set(StorageKeys.CLOUD_TOKEN, cloudAuthToken);
    showSuccess("Signin Successful", { autoClose: 1500 });
    setTimeout(() => {
      nav("/choirboard");
    }, 1500);
  }, []);

  const { mutate, isLoading } = useMutation({
    mutationKey: ["signin-after-reset"],
    mutationFn: fn,
  });

  return { fn: mutate as LoginFn, isLoading };
};
