import styled from "styled-components";
import { Typography, TypographyProps } from "..";
import React from "react";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

export const Cover = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 45px;
  object-fit: cover;
`;

export const MetadataContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
`;

const Small = ({ children, ...props }: Omit<TypographyProps, "variant">) =>
  React.createElement(Typography, { variant: "pMid", ...props }, children);

const Tiny = ({ children, ...props }: Omit<TypographyProps, "variant">) =>
  React.createElement(Typography, { variant: "tiny", ...props }, children);

export const ComposerText = styled(Tiny)`
  margin: 4px 0;
`;
export const TitleText = styled(Small)``;

export const OptionsIcon = styled.img`
  margin-left: auto;
  height: 20px;
  width: 20px;
`;
