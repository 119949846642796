import * as React from "react";
import { useDispatch } from "react-redux";
import { syncCreateAlbumData } from "../../../../../redux/slices/createAlbum";
import { useAlbumModalState } from "../../../hooks/useAlbumModalState";
import { AlbumMetaModal } from "./AlbumMeta";

interface IPhase1Props {
  nextStep?: () => void;
  step?: number;
}

const Phase1: React.FunctionComponent<IPhase1Props> = ({ nextStep }) => {
  const dispatch = useDispatch();
  const {
    data: { meta },
    toggleOpen: toggleAlbumModal,
  } = useAlbumModalState();

  const handleNext = ({ target }: any) => {
    const payload = target.value;
    dispatch(syncCreateAlbumData(payload));
    nextStep?.();
  };

  const handleOnCancelAlbumUpload = () => {
    toggleAlbumModal();
  };

  return (
    <AlbumMetaModal
      meta={meta}
      header="Create Album Meta"
      onClickCancel={handleOnCancelAlbumUpload}
      onClickNext={handleNext}
    />
  );
};

export default Phase1;
