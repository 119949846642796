import React, { useState } from "react";
import { UploadSongPayload } from "../singleSongUpload/root";
import { defaultAlbumSong } from "../../../../../redux/slices/createAlbum";
import { AddAlbumSong } from "./AddSong";
import { AddAlbumSongPreview } from "./Preview";

interface AddAlbumSongFlowProps {
  onClickUpload: (payload: any) => void;
  onCloseFlow: () => void;
  header: string;
}

export const AddAlbumSongFlow = ({
  onClickUpload,
  header,
  onCloseFlow,
}: AddAlbumSongFlowProps) => {
  const [step, setStep] = useState(1);
  const [songPayload, setSongPayload] = useState<Partial<UploadSongPayload>>({
    ...defaultAlbumSong,
  });

  const handleToNextStep = (payload: any) => {
    setStep((p) => p + 1);
    setSongPayload(payload);
  };
  const handleToPrevStep = () => {
    setStep((p) => p - 1);
  };

  switch (step) {
    case 1:
      return (
        <AddAlbumSong
          onCloseModal={onCloseFlow}
          onClickNext={handleToNextStep}
          header={header}
          defaultData={songPayload}
        />
      );
    case 2:
      return (
        <AddAlbumSongPreview
          data={songPayload}
          onClickPrev={handleToPrevStep}
          onClickUpload={() => onClickUpload(songPayload)}
        />
      );
    default:
      return null;
  }
};
