import { IChoirNotificationDocument } from "@chords/core";
import { Button, Typography } from "@chords/design-system";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { choirService } from "../../application/choir/choir.service";
import { useAuth } from "../_shared/hooks/useAuth";

interface Props {
  data: IChoirNotificationDocument;
  onClose?: () => void;
}

export const DisplayNotification = ({ data, onClose }: Props) => {
  const id = useAuth().user!.id;
  const { mutate: readNotification } = useMutation({
    mutationFn: () => choirService.readNotification(data?.id, id),
  });

  useEffect(() => {
    if (!data?.read) {
      readNotification();
    }
  }, [data]);

  return (
    <div className="w-modal min-h-[150px] flex flex-col ">
      <Typography className="mb-2" variant="pMid">
        {data?.data}
      </Typography>
      <Button onClick={onClose} className="!w-full mt-auto" variant="auth">
        Close
      </Button>
    </div>
  );
};
