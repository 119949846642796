import { UploadEvents } from "./events";

export class UploadEventManager {
  private constructor() {
    //
  }

  private static get stackManagerComponent() {
    return document.getElementById("upload-stack-manager");
  }

  public static emitSongUpload = (payload: any) => {
    const ev = new CustomEvent(UploadEvents.UPLOAD_SONG, {
      detail: { payload: { ...payload, type: "single" } },
    });
    const manager = this.stackManagerComponent;
    if (manager) {
      manager.dispatchEvent(ev);
    }
  };

  public static emitAlbumUpload = (payload: any) => {
    const ev = new CustomEvent(UploadEvents.UPLOAD_ALBUM, {
      detail: { payload: { ...payload, type: "album" } },
    });
    const manager = this.stackManagerComponent;
    if (manager) {
      manager.dispatchEvent(ev);
    }
  };

  public static emitAlbumSongUpload = (payload: any) => {
    const ev = new CustomEvent(UploadEvents.UPLOAD_ALBUM, {
      detail: { payload: { ...payload, type: "album-song" } },
    });
    const manager = this.stackManagerComponent;
    if (manager) {
      manager.dispatchEvent(ev);
    }
  };
}
