import React from "react";
import {
  Navigate,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import App from "./App";
import ChoirBoard from "./modules/choirBoard/ChoirBoard";
import Uploads from "./modules/uploads/Uploads";
import { Concert } from "./modules/concert";
import Settings from "./modules/settings/Settings";
import { NotificationPage } from "./modules/notification";
import { AlbumPage } from "./modules/AlbumPage";
import { ToastContainer } from "react-toastify";
import { ResetPassword, Signin, Signup } from "./modules/auth";
import { ProtectedRoute } from "./lib/hocs/AthProtectedRoute";

const router = createBrowserRouter(
  createRoutesFromElements(
    <React.Fragment>
      <Route path="/signin" element={<Signin />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/password-reset" element={<ResetPassword />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <App />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to="/choirboard" replace />} />
        <Route path="/choirboard" element={<ChoirBoard />} />
        <Route path="/uploads" element={<Uploads />} />
        <Route path="/concert" element={<Concert />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/notifications" element={<NotificationPage />} />
        <Route path="/album/:id" element={<AlbumPage />} />
      </Route>
    </React.Fragment>
  )
);

export const UIRoot = () => {
  return (
    <React.Fragment>
      <ToastContainer
        autoClose={1500}
        position="top-right"
        closeOnClick
        hideProgressBar
        theme="dark"
      />
      <RouterProvider router={router} />
    </React.Fragment>
  );
};
