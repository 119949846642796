import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../modules/_shared/hooks/useAuth";

interface AuthProtectedRoutesProps {
  children: any;
  to?: string;
}

export const ProtectedRoute = ({ children, to }: AuthProtectedRoutesProps) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return children;
  }
  return <Navigate to={to ?? "signin"} />;
};
