import { Modal } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000060;
`;

export const CustomModal = styled(Modal)`
  border: none;
`;
