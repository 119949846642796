import React from "react";
import { CircularProgress, circularProgressClasses } from "@mui/material";

interface LoaderProps {
  color?: string;
}
export const Loader = ({ color = "#000" }: LoaderProps) => {
  return (
    <div
      style={{
        position: "relative",
        width: "fit-content",
        margin: "auto auto",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color,
        }}
        size={25}
        thickness={3}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) =>
            theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: "round",
          },
        }}
        size={25}
        thickness={3}
      />
    </div>
  );
};
