import { Button, Song, Typography } from "@chords/design-system";
import React, { useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { choirService } from "../application/choir/choir.service";
import { Icon } from "@iconify/react";
import { EditAlbumMeta } from "./_shared/components/modal/albumUpload/EditAlbumMeta";
import { RenderCondition, RenderIf } from "@chords/design-system/render";
import { UploadEventManager } from "../lib/events/uploadEventManager";
import { AddAlbumSongFlow } from "./_shared/components/modal/addAlbumSong";
import { EditAlbumSong } from "./_shared/components/modal/addAlbumSong/EditAlbumSong";
import { DeleteAlbumSongModal } from "./_shared/components/modal/DeleteAlbumSongPrompt";
import { songUploadUtilService } from "../application/song";
import { UploadSongPayload } from "./_shared/components/modal/singleSongUpload/root";
import { useToastManager } from "./_shared/hooks/useToastManager";
import { useAuth } from "./_shared/hooks/useAuth";
import { useAlbum } from "./album/hooks/useAlbum";
import { useAlbumSongs } from "./album/hooks/useAlbumSongs";
import { IAlbumSummaryForResource } from "@chords/core";
import { isOutdatedResourceError } from "./uploads/errorhandlers/outdatedResource";

interface ModalStates {
  editModal: boolean;
  addSong: boolean;
}

const initialModalState: ModalStates = {
  addSong: false,
  editModal: false,
};

export const AlbumPage = () => {
  const { user } = useAuth();
  const choirId = user?.id ?? "";
  const { id } = useParams();
  const [modalStates, setModalStates] = useState(initialModalState);
  const [toEdit, setToEdit] = useState<Partial<UploadSongPayload> | null>(null);
  const [toDelete, setToDelete] = useState<Partial<UploadSongPayload> | null>(
    null
  );
  const { show } = useToastManager();
  const deleteModalRef = useRef<HTMLDialogElement>(null);
  const deleteAlbumModalRef = useRef<HTMLDialogElement>(null);
  const { data: meta } = useAlbum(id ?? "");
  const {
    data: albumSongs,
    loading: loadingAlbumSongs,
    updateSong,
  } = useAlbumSongs(id ?? "");
  const queryCient = useQueryClient();

  const { mutateAsync, isLoading: isUpdatingAlbumMeta } = useMutation({
    mutationKey: [`update-album-song`, `${(toEdit as any)?.id}`],
    mutationFn: async (payload: any) => {
      await choirService.updateSingleSong(
        choirId,
        (toEdit as any)?.id,
        payload
      );
      return Promise.resolve(payload);
    },
    onSuccess: (payload: any) => {
      updateSong({ id: (toEdit as any)?.id, ...payload });
    },
  });

  const { mutateAsync: deleteAsync, isLoading: isDeletingAlbumSong } =
    useMutation({
      mutationKey: [`delete-album-song`, `${(toDelete as any)?.id}`],
      mutationFn: () =>
        choirService.removeAlbumSong(
          choirId,
          id as string,
          (toDelete as any)?.id
        ),
    });

  const { mutateAsync: deleteAlbumAsync, isLoading: isDeletingAlbum } =
    useMutation({
      mutationKey: [`delete-album`, `${id}`],
      mutationFn: () => choirService.removeAlbum(choirId, id as string),
    });

  const handleOpenEditModal = () => {
    //
    setModalStates((p) => ({ ...p, editModal: true }));
  };
  const handleOpenAddSongModal = () => {
    //
    setModalStates((p) => ({ ...p, addSong: true }));
  };

  const handleCloseModals = () => {
    setModalStates(initialModalState);
  };

  const handleOpenDeleteAlbumModal = () => {
    deleteAlbumModalRef.current?.showModal();
  };

  const handleOnEditMeta = () => {
    show(`Successfully updated ${meta?.title}`, { type: "success" });
    queryCient.invalidateQueries({
      queryKey: [`${id}-albumPage`, "meta"],
      refetchActive: true,
      stale: true,
    });
    handleCloseModals();
  };

  const handleOnAddSong = (payload: any) => {
    //
    show("Uploading Album song. Do not close or refresh the page", {
      type: "info",
      hideProgressBar: true,
    });
    const albumSummary: IAlbumSummaryForResource = {
      coverImage: meta.coverImage,
      id: meta.id,
      title: meta.title,
    };
    UploadEventManager.emitAlbumSongUpload({ ...payload, albumSummary });
    handleCloseModals();
  };

  const handleSetToEditSong = (song: any) => {
    const data = songUploadUtilService.castSongArrayFields(song);
    setToEdit(data);
  };

  const handleSetToDeleteSong = (song: any) => {
    setToDelete(song);
    deleteModalRef.current?.showModal();
  };

  const handleOnEditAlbumSongMeta = async (song: any) => {
    //
    if (toEdit) {
      const rawPayload = songUploadUtilService.createMetaDiff(toEdit, song);
      const parsedPayload =
        songUploadUtilService.createEditAlbumPayload(rawPayload);
      const proceed = Object.values(parsedPayload).flatMap((d) => d).length > 0;
      if (proceed) {
        await mutateAsync(parsedPayload);
        show("Updated Album meta", { type: "success" });
      } else {
        alert("There is nothing to edit");
      }
    }
    handleCloseToEditModal();
  };

  const handleCloseToEditModal = () => {
    setToEdit(null);
  };

  const handleCloseDeleteModal = () => {
    deleteModalRef.current?.close();
  };

  const handleDeleteSong = async () => {
    //
    try {
      await deleteAsync();
      show(`Successfully deleted ${toDelete?.title}`, { type: "success" });
      handleCloseDeleteModal();
    } catch (error: any) {
      if (isOutdatedResourceError(error)) {
        alert(
          "You are unable to delete this resource because Chords managed its earlier release. Reach out to support for assistance to delete"
        );
      } else {
        show(`Failed to delete ${toDelete?.title}`, { type: "error" });
      }
    }
  };

  const handleDeleteAlbum = async () => {
    //
    try {
      await deleteAlbumAsync();
      show(`Successfully deleted album`, { type: "success" });
      execWithDelay(() => {
        window.location.href = `${location.origin}/uploads`;
      }, 2000);
    } catch (error: any) {
      if (isOutdatedResourceError(error)) {
        alert(
          "You are unable to delete this resource because Chords managed its earlier release. Reach out to support for assistance to delete"
        );
      } else {
        show(`Failed to delete Album`, { type: "error" });
      }
    }
  };

  const handleCloseDeleteAlbumModal = () => {
    deleteAlbumModalRef.current?.close();
  };

  return (
    <main>
      <div className="relative h-concertBanner flex justify-between">
        <img
          src={meta?.coverImage}
          className="absolute z-[-1] w-full h-full object-cover"
        />
        <div className="flex flex-col text-white py-10 pl-8 bg-gradient-to-r from-[#000] from-10% via-[#00000090] to-[transparent]">
          <div className="mt-auto h-fit">
            <Typography variant="h3">{meta?.title}</Typography>
            <div className="flex gap-x-4">
              <div className="flex">
                <Typography variant="p"></Typography>
              </div>
              <div className="flex">
                <Typography variant="p"></Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-between p-8">
        <div className="flex items-center gap-x-4">
          <Button onClick={handleOpenEditModal} className="">
            Edit Details
          </Button>
          <Button
            onClick={handleOpenAddSongModal}
            variant="outlined"
            className=""
          >
            Add Song
          </Button>
        </div>
        <Button
          onClick={handleOpenDeleteAlbumModal}
          variant="error"
          className=""
        >
          Delete
        </Button>
      </div>
      <section className="px-8 mt-10">
        <Typography variant="h4">Songs</Typography>
        <div className="flex flex-col gap-y-2">
          {RenderCondition(
            loadingAlbumSongs,
            <Typography variant="small">loading</Typography>,
            (albumSongs as any)?.map((song: any) => {
              return (
                <div
                  onClick={() => handleSetToEditSong(song)}
                  key={song.id}
                  className="flex justify-between items-center hover:bg-chordsLightBg px-2"
                >
                  <Song
                    className="!m-0 pt-1 !mt-0 w-[80%] hover:cursor-pointer"
                    data={song}
                  />
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSetToDeleteSong(song);
                    }}
                    variant="nav"
                    className="ml-auto"
                  >
                    <Icon icon="fluent:delete-20-regular" />
                  </Button>
                </div>
              );
            })
          )}
        </div>
      </section>
      {RenderIf(
        modalStates.editModal,
        <EditAlbumMeta
          onCancel={handleCloseModals}
          onEdit={handleOnEditMeta}
          meta={meta}
        />
      )}
      {RenderIf(
        modalStates.addSong,
        <AddAlbumSongFlow
          onCloseFlow={handleCloseModals}
          header={`Add Song to ${meta?.title}`}
          onClickUpload={handleOnAddSong}
        />
      )}
      {RenderIf(
        !!toEdit,
        <EditAlbumSong
          data={toEdit!}
          header={`Edit ${toEdit?.title}`}
          onClickEdit={handleOnEditAlbumSongMeta}
          onCloseFlow={handleCloseToEditModal}
          phaseContainerProps={{
            nextbuttonProps: { isLoading: isUpdatingAlbumMeta },
          }}
        />
      )}
      <dialog ref={deleteModalRef}>
        <DeleteAlbumSongModal
          header={`Delete "${toDelete?.title}"?`}
          onClickCancel={handleCloseDeleteModal}
          onClickDelete={handleDeleteSong}
          deleteButtonProps={{ isLoading: isDeletingAlbumSong }}
        />
      </dialog>
      <dialog ref={deleteAlbumModalRef}>
        <DeleteAlbumSongModal
          header={`Delete "${meta?.title}"?. This will delete all songs as well.`}
          onClickCancel={handleCloseDeleteAlbumModal}
          onClickDelete={handleDeleteAlbum}
          deleteButtonProps={{ isLoading: isDeletingAlbum }}
        />
      </dialog>
    </main>
  );
};

function execWithDelay(fn: any, delay?: number) {
  setTimeout(() => {
    fn();
  }, delay ?? 2000);
}
