import { query, onSnapshot, where, getDoc, doc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Repositories } from "../../../infra/repositories";
import { RepositoryRefs } from "../../../infra/repositories/types";

export const useAlbumSongs = (albumId: string) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState(false);

  const updateSong = (song: any) => {
    setData((p) => {
      const updated = p.map((doc) => {
        if (song.id === doc.id) {
          return { ...doc, ...song };
        }
        return doc;
      });
      return updated;
    });
  };

  useEffect(() => {
    if (!albumId) {
      setLoading(false);
      setError(false);
      setData([]);
      return;
    }
    setLoading(true);
    setError(false);
    const qRef = query(Repositories.albumSong, where("albumId", "==", albumId));
    const unSub = onSnapshot(
      qRef,
      async (observer) => {
        const songIds = observer.docs.map((doc) => doc.data().songId);
        console.log(songIds);
        const docs = await Promise.all(
          songIds.map((id) =>
            getDoc(doc(Repositories.db, RepositoryRefs.song, id))
          )
        );
        const results = docs.map((doc) => {
          if (doc.data()) {
            return { ...doc.data(), id: doc.id };
          }
        });
        setData(results);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
        setError(true);
      }
    );

    return function () {
      unSub?.();
    };
  }, []);

  return {
    loading,
    error,
    data,
    updateSong,
  };
};
