import { ICreatorSummaryForResource, ISongBase } from "@chords/core";
import { UploadSongPayload } from "../../modules/_shared/components/modal/singleSongUpload/root";
import { castStringToArray } from "../../lib/helper";

function createSongPayload(
  data: Partial<UploadSongPayload>,
  creatorSummary: ICreatorSummaryForResource
) {
  const local = { ...data };
  delete local.audioSrc;
  delete local.coverImage;

  const soloists =
    data.soloists?.split(",").map((sol: string) => sol.trim()) ?? [];
  const hymnRefs =
    data.hymnRefs?.split(",").map((ref: string) => ref.toLowerCase().trim()) ??
    [];
  const relatedKeywords =
    data.relatedKeywords?.split(",").map((keyw: string) => keyw.trim()) ?? [];
  const composedDate = local.composedDate
    ? new Date(local.composedDate).getTime()
    : 0;
  return {
    meta: {
      ...local,
      soloists,
      hymnRefs,
      relatedKeywords,
      genre: `${local.genre}`,
      composedDate,
    },
    type: data.type,
    creatorSummary,
    files: { cover: data.coverImage, audio: data.audioSrc },
  };
}
function createAlbumPayload(
  data: any,
  creatorSummary: ICreatorSummaryForResource
) {
  const local = { ...data.meta };
  delete local.coverImage;

  return {
    meta: local,
    songs: data.songs.map((song: any) => {
      const { meta } = createSongPayload(song, creatorSummary);
      delete meta.audioSrc;

      return {
        meta,
        audioSrc: song.audioSrc,
      };
    }),
    type: data.type,
    cover: data.meta.coverImage,
    creatorSummary,
  };
}

function createAlbumSongPayload(
  data: any,
  creatorSummary: ICreatorSummaryForResource
) {
  const payload = createSongPayload(data, creatorSummary);
  return { ...payload, albumSummary: data.albumSummary };
}

// do not belong here but in a global utils in the app layer
function createMetaDiff(oldMeta: any, newMeta: any) {
  //
  const diff: any = {};
  for (const field in oldMeta) {
    const oldVal = oldMeta[field];
    const respectiveNewVal = newMeta[field];
    if (respectiveNewVal && oldVal !== respectiveNewVal) {
      diff[field] = respectiveNewVal;
    }
  }
  return diff;
}

function createEditAlbumPayload(data: any) {
  const $data: any = { ...data } as ISongBase;
  const fields = ["hymnRefs", "soloists", "relatedKeywords"];
  for (const field of fields) {
    if ($data?.[field]) {
      $data[field] = castStringToArray($data[field], ",");
    }
  }
  return { ...$data };
}

function castSongArrayFields(data: any) {
  const $data = { ...data };
  const fields = ["hymnRefs", "soloists", "relatedKeywords"];
  for (const field of fields) {
    if ($data[field] && Array.isArray($data[field])) {
      $data[field] = $data[field].join(",");
    }
  }
  return $data;
}

export const songUploadUtilService = {
  createSongPayload,
  createAlbumPayload,
  createAlbumSongPayload,
  createMetaDiff,
  createEditAlbumPayload,
  castSongArrayFields,
} as const;
