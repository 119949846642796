import { configureStore } from "@reduxjs/toolkit";
import { createSongReducer } from "./slices/createSongSlice";
import { createAlbumReducer } from "./slices/createAlbum";
import { persistStore } from "redux-persist";

import { authReducer } from "./slices/auth.slice";

const store = configureStore({
  reducer: {
    createSong: createSongReducer,
    createAlbum: createAlbumReducer,
    auth: authReducer,
  },
  middleware: (gDM) =>
    gDM({
      serializableCheck: {
        ignoreActions: true,
      },
    }),
});

export const persistedStore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
