import React, { HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import "./index.css";
export type AssetStatus = "approved" | "rejected" | "pending";

export interface TypographyProps
  extends HTMLAttributes<HTMLHtmlElement | HTMLHeadingElement> {
  variant:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "p"
    | "pMid"
    | "link"
    | "lStatus"
    | "tiny"
    | "small"
    | "error"
    | "tag";
  isError?: boolean;
  isSuccess?: boolean;
  weight?: string;
  status?: AssetStatus;
  isLabel?: boolean;
}

const error = css`
  color: #ed2b2a;
`;

const success = css`
  color: #5ca52d;
`;

const label = css`
  color: #a9a9a9;
`;

const h1 = styled.h1`
  font-size: 60px;
  ${({ isError }: any) => isError && error};
  ${({ isSuccess }: any) => isSuccess && success};
  ${({ isLabel }: any) => isLabel && label};
  font-variation-settings: ${({ weight }: any) => `"wght" ${weight ?? "500"}`};
`;

const h2 = styled.h2`
  font-size: 48px;
  ${({ isError }: any) => isError && error};
  ${({ isSuccess }: any) => isSuccess && success};

  ${({ isLabel }: any) => isLabel && label};
  font-variation-settings: ${({ weight }: any) => `"wght" ${weight ?? "500"}`};
`;
const h3 = styled.h3`
  font-size: 32px;
  ${({ isError }: any) => isError && error};
  ${({ isSuccess }: any) => isSuccess && success};

  ${({ isLabel }: any) => isLabel && label};
  font-variation-settings: ${({ weight }: any) => `"wght" ${weight ?? "500"}`};
`;
const h4 = styled.h4`
  font-size: 24px;
  ${({ isError }: any) => isError && error};
  ${({ isSuccess }: any) => isSuccess && success};

  ${({ isLabel }: any) => isLabel && label};
  font-variation-settings: ${({ weight }: any) => `"wght" ${weight ?? "500"}`};
`;

const h5 = styled.h5`
  font-size: 20px;
  ${({ isError }: any) => isError && error};
  ${({ isSuccess }: any) => isSuccess && success};

  ${({ isLabel }: any) => isLabel && label};
  font-variation-settings: ${({ weight }: any) => `"wght" ${weight ?? "500"}`};
`;

const h6 = styled.h5`
  font-size: 16px;
  ${({ isError }: any) => isError && error};
  ${({ isSuccess }: any) => isSuccess && success};

  ${({ isLabel }: any) => isLabel && label};
  font-variation-settings: ${({ weight }: any) => `"wght" ${weight ?? "500"}`};
`;

const p = styled.p`
  font-size: 16px;
  font-variation-settings: "wght" 400;
  ${({ isError }: any) => isError && error};
  ${({ isSuccess }: any) => isSuccess && success};

  ${({ isLabel }: any) => isLabel && label};
`;

const pMid = styled(p)`
  font-size: 14px;
  ${({ isError }: any) => isError && error};
  ${({ isSuccess }: any) => isSuccess && success};

  ${({ isLabel }: any) => isLabel && label};
  font-variation-settings: ${({ weight }: any) => `"wght" ${weight ?? "500"}`};
`;

const small = styled.p`
  font-size: 12px;
  font-variation-settings: "wght" 400;
  ${({ isError }: any) => isError && error};
  ${({ isSuccess }: any) => isSuccess && success};

  ${({ isLabel }: any) => isLabel && label};
  font-variation-settings: ${({ weight }: any) => `"wght" ${weight ?? "500"}`};
`;

const tiny = styled.p`
  font-size: 10px;
  font-variation-settings: "wght" 400;
  ${({ isError }: any) => isError && error};
  ${({ isSuccess }: any) => isSuccess && success};

  ${({ isLabel }: any) => isLabel && label};
`;

const tag = styled.span`
  ${({ isError }: any) => isError && error};
  ${({ isSuccess }: any) => isSuccess && success};

  font-size: 8px;
  font-family: "acumin";
  font-variation-settings: "wght" 700;
`;

const lStatus = styled(small)<{ status: string }>`
  color: ${({ status }: any) => getStatusColor(status)};
  border: ${({ status }: any) => `1px solid ${getStatusColor(status)}40`};
  padding: 2px 4px;
  border-radius: 3px;
`;

const Store: any = {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  small,
  tiny,
  tag,
  lStatus,
  pMid,
};

export const Typography = ({ variant, ...props }: TypographyProps) => {
  const Element = Store[variant];
  return <Element {...(props as any)} />;
};

function getStatusColor(status: AssetStatus) {
  return status === "approved"
    ? "#5ca52d"
    : status === "rejected"
    ? "#ed2b2a"
    : "#0091b0";
}
