import { query, onSnapshot, where, orderBy } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Repositories } from "../../../infra/repositories";
import { useAuth } from "../../_shared/hooks/useAuth";

export const useNotifications = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState(false);
  const { id } = useAuth().user!;

  useEffect(() => {
    setLoading(true);
    setError(false);
    const qRef = query(
      Repositories.creatorNotification,
      where("creatorId", "==", id),
      orderBy("createdAt", "desc")
    );
    const unSub = onSnapshot(
      qRef,
      (observer) => {
        const res = observer.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setData(res);
      },
      (err) => {
        console.log("-----");
        console.log("notifications subscription err");
        console.log(err);
        console.log("-----");
        setLoading(false);
        setError(true);
      }
    );

    return function () {
      unSub?.();
    };
  }, [id]);

  return {
    loading,
    error,
    data,
  };
};
