import React, { useMemo } from "react";
import { useMutation } from "react-query";
import { ConcertModal } from "./ModalView";
import { concertService } from "../../application/concert/concert.service";
import { prepareConcertParams } from "./prepareConcertParams";
import { Button } from "@chords/design-system";
import { useAuth } from "../_shared/hooks/useAuth";
import { toast } from "react-toastify";

export const EditConcert = ({ concert, onClose, onSave }: any) => {
  const id = useAuth().user!.id;
  const { mutateAsync: editConcert, isLoading: isUpdatingConcert } =
    useMutation({
      mutationFn: (params) =>
        concertService.editConcert(concert.id, id, params),
    });

  const { mutate: deleteConcert, isLoading: isDeleting } = useMutation({
    mutationFn: () => concertService.deleteConcert(concert.id, id),
    onSuccess: onSave,
  });

  const { date, time } = useMemo(() => {
    const date = new Date(concert.dueDate).toISOString().split("T");
    return { date: date[0], time: date[1].substring(0, 5) };
  }, [concert]);

  const isUpcoming = useMemo(() => {
    return new Date().getTime() < concert.dueDate;
  }, [concert]);

  const header = useMemo(() => {
    if (isUpcoming) return `Edit ${concert.title}`;
    return `${concert.title}`;
  }, [isUpcoming, concert]);

  const handleEditConcert = async (meta: any) => {
    const dueDate = prepareConcertParams.getDateAndTime(meta.date, meta.time);
    const toEdit = compareParams(concert, {
      ...meta,
      dueDate,
    });
    const errors = createErrors({ ...meta, ...toEdit });
    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      toast("Form is incomplete", { hideProgressBar: true, type: "info" });
      return;
    }
    if (Object.keys(toEdit).length > 0) {
      await editConcert(toEdit as any)
        .then(() => {
          toast("Successfully updated concert", {
            type: "success",
            hideProgressBar: true,
          });
          onSave();
        })
        .catch(() =>
          toast("Failed to update concert. Try Again", {
            type: "error",
            hideProgressBar: true,
          })
        );
    } else {
      alert("nothing to edit");
    }
  };

  const handleDelete = async () => {
    deleteConcert();
  };

  return (
    <ConcertModal
      header={header}
      onSave={handleEditConcert}
      containerProps={{
        nextButtonLabel: "Update Concert",
        cancelbuttonProps: { className: "text-[#00000090]" },
        onClickCancel: onClose,
        style: { height: "550px" },
        footerComponent: ({ meta }: any) => {
          return (
            <div className="w-full flex items-center">
              <Button
                isLoading={isDeleting}
                onClick={handleDelete}
                variant="error"
              >
                Delete
              </Button>

              <div className="ml-auto w-fit flex items-center gap-x-5">
                <Button
                  onClick={onClose}
                  variant="cancel"
                  className="text-[#00000090]"
                >
                  Cancel
                </Button>
                <Button
                  isLoading={isUpdatingConcert}
                  disabled={!isUpcoming}
                  onClick={() => handleEditConcert(meta)}
                  variant="auth"
                  className="text-[#00000090]"
                >
                  Update Concert
                </Button>
              </div>
            </div>
          );
        },
      }}
      meta={{ ...concert, date, time }}
      selectButtonProps={{ disabled: true }}
    />
  );
};

function compareParams(oldParams: any, newParams: any) {
  const toEdit: any = {};
  for (const param in newParams) {
    const newVal = newParams[param];
    if (oldParams?.[param] && newVal !== oldParams?.[param]) {
      toEdit[param] = newVal;
    }
  }
  return toEdit;
}
const createErrors = (payload: any) => {
  const errors: any = {};
  if (!payload.title) errors.title = "Required";
  if (!payload.location) errors.location = "Required";
  if (!payload.date) errors.date = "Required";
  if (!Object.keys(payload.time).length) errors.time = "Required";
  if (!payload.coverImage) errors.coverImage = "Reqiured";
  return errors;
};
