import { Button, Typography } from "@chords/design-system";
import React, { useState } from "react";
import { SignuForm } from "./form";
import { RenderCondition } from "@chords/design-system/render";
import { useNavigationWithReload } from "../../_shared/hooks/useAppNavigation";
import { useLogin } from "../../_shared/hooks/useLogin";
import { ILoginPayload } from "../../../application/auth/types";

type FormState = "progress" | "complete";
interface IFormPayload extends ILoginPayload {
  state: FormState;
}

export const Signup = () => {
  const [formState, setFormState] = useState<IFormPayload>({
    token: "",
    cloudAuthToken: "",
    state: "progress",
  });
  const nav = useNavigationWithReload();
  const { fn: login, isLoading: loadingLogin } = useLogin();

  const handleOnSignupComplete = (payload: ILoginPayload) => {
    setFormState({ ...payload, state: "complete" });
  };

  const handleSignin = () => {
    if (formState.token && formState.cloudAuthToken) {
      login({
        cloudAuthToken: formState.cloudAuthToken,
        token: formState.token,
      });
    }
  };

  const toSignin = () => {
    nav("/signin");
  };

  return (
    <div className="w-screen h-screen">
      <header className="flex h-[80px] bg-black">
        <div className="container flex items-center">
          <Button
            onClick={toSignin}
            variant="auth"
            className="!border-[1px] !border-[#fff] !border-solid ml-auto"
          >
            Sign In
          </Button>
        </div>
      </header>
      <main className="container flex flex-col mx-auto">
        <div className="mx-auto flex flex-col pt-10">
          <Typography variant="h1">Join the Chords Train!</Typography>
          <Typography variant="h3">
            The biggest Choral Music revolution is HERE!
          </Typography>
        </div>
        {RenderCondition(
          formState.state === "progress",
          <SignuForm onComplete={handleOnSignupComplete} />,
          <div className="flex flex-col items-center mt-20">
            <Typography variant="h5">Welcome to Chords 2c 🎉</Typography>
            <Typography variant="p">
              Reach out to our support team for any assistance
            </Typography>
            <Button
              onClick={handleSignin}
              variant="auth"
              className="!w-[350px] mt-10"
              isLoading={loadingLogin}
            >
              Open your Choir Board
            </Button>
          </div>
        )}
      </main>
    </div>
  );
};
