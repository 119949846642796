export const SupportedImageFormatMap = Object.freeze({
  "image/png": true,
  "image/jpeg": true,
  "image/webp": true,
});

export const SupportedAudioFormatMap = Object.freeze({
  audio: true,
});

export type SupportedImageFormatType = keyof typeof SupportedImageFormatMap;
