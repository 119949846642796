const storage = localStorage;

export class ClientStorage<Key = any> {
  set = (key: Key, data: any) => {
    if (!data) return;
    const stringedData = JSON.stringify(data);
    storage.setItem(key as string, stringedData);
  };

  get = (key: Key) => {
    const data = storage.getItem(key as string);
    if (data) return JSON.parse(data);
  };

  clearItem = (key: Key) => {
    storage.removeItem(key as string);
  };

  clear = () => {
    storage.clear();
  };
}
