import {
  resetAlbum as reset,
  toggleCreateAlbumModal,
} from "../../../redux/slices/createAlbum";
import { useCreateAlbumState } from "./useCreateSongState";
import { useAppDispatch } from "./useRedux";

/**
 *
 * @returns
 * - open -> boolean;
 * - toggleOpen -> fn;
 * - data -> albumSlice values
 *
 * Hook to interact with the AlbumUpload global state
 */
export const useAlbumModalState = () => {
  const dispatch = useAppDispatch();
  const { open, ...albumState } = useCreateAlbumState();

  const toggleOpen = () => {
    dispatch(toggleCreateAlbumModal());
  };

  const resetAlbum = () => {
    dispatch(reset());
  };

  return { open, toggleOpen, data: albumState, resetAlbum };
};
