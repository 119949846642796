import React from "react";
import { Button, ButtonProps, Typography } from "@chords/design-system";

interface DeleteModalProps {
  header: string;
  cancelButtonProps?: ButtonProps;
  deleteButtonProps?: ButtonProps;
  onClickCancel: () => void;
  onClickDelete: () => void;
}

export const DeleteAlbumSongModal = ({
  header,
  cancelButtonProps,
  deleteButtonProps,
  onClickCancel,
  onClickDelete,
}: DeleteModalProps) => {
  return (
    <div className="w-modal min-h-[140px] flex flex-col ">
      <Typography className="mb-2" variant="pMid">
        {header}
      </Typography>
      <div className="flex mt-auto gap-x-8 justify-between items-center">
        <Button
          className="!w-full mt-auto"
          variant="outlined"
          onClick={onClickCancel}
          {...cancelButtonProps}
        >
          Cancel
        </Button>
        <Button
          className="!w-full mt-auto"
          variant="error"
          onClick={onClickDelete}
          {...deleteButtonProps}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};
