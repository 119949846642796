import { createSlice } from "@reduxjs/toolkit";
import { UploadSongPayload } from "../../modules/_shared/components/modal/singleSongUpload/root";

type UploadSongType = Partial<Omit<UploadSongPayload, "composedDate">> & {
  composedDate?: string;
};

export const defaultSong: UploadSongType = {
  title: "",
  description: "",
  composer: "",
  conductor: "",
  soloists: "",
  producedBy: "",
  composedDate: "",
  relatedKeywords: "",
  genre: undefined,
  theme: [],
  hymnRefs: "",
  coverImage: null,
  audioSrc: null,
};

interface CreateSongFlowState {
  open: boolean;
  data: Partial<UploadSongPayload>;
}

const initialState: CreateSongFlowState = {
  data: defaultSong,
  open: false,
};

export const createSongSlice = createSlice({
  name: "createSong",
  initialState,
  reducers: {
    toggleOpen: (state) => {
      state.open = !state.open;
    },
    syncData: (state, { payload }) => {
      state.data = payload;
    },
    resetSong: (state) => {
      state.data = defaultSong;
    },
  },
});

export const {
  syncData: syncCreateSongData,
  toggleOpen: toggleCreateSongModal,
  resetSong,
} = createSongSlice.actions;

export const { reducer: createSongReducer } = createSongSlice;
